import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

function Navbar({ auth }) {
    return (
        <header className='main'>
            <div className="container-fluid">
                <div className="d-flex">
                    <div className="logo">
                        <NavLink to={"/"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="132" height="32" viewBox="0 0 132 32" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10.0011V30.3971C0 31.7012 1.47633 32.4575 2.53473 31.6957L22.6733 17.1997C26.0057 14.4613 27.3935 6.55179 21.8977 2.24977C19.1134 0.070269 15.3185 1.31064e-05 11.7826 8.31344e-06L4.9547 0C3.73797 0 2.46525 0.0795202 1.5015 0.822221C0.754271 1.39805 0.10595 2.24946 0.10595 3.35201C0.10595 4.58754 0.0699262 6.35222 0.0392973 7.85262C0.0219754 8.70116 0.00637897 9.46517 0 10.0011ZM7.15078 28.228V12.8592C7.15078 11.8818 7.31132 10.8905 7.86809 10.0872C9.05163 8.37958 11.3372 6.50366 14.6732 7.33045C19.8118 8.91693 17.0571 13.0199 16.5803 13.5669C16.5501 13.6016 16.5163 13.6422 16.4783 13.6877C15.9171 14.3613 14.4509 16.1207 10.3823 15.8645V25.9304L7.15078 28.228Z" fill="#EF225D" />
                                <ellipse cx="21.6443" cy="27.2097" rx="3.57647" ry="3.71932" fill="#EF225D" />
                                <path d="M49.664 25.2632H45.8798L41.7312 18.2737H38.4796V25.2632H35.2V6.54038H43.4131C45.2444 6.54038 46.7207 7.08307 47.842 8.16845C48.9632 9.25383 49.5238 10.6667 49.5238 12.407C49.5238 13.979 49.094 15.2421 48.2344 16.1965C47.3748 17.1322 46.3283 17.7029 45.0949 17.9088L49.664 25.2632ZM42.9646 15.3825C43.8989 15.3825 44.6651 15.1111 45.2631 14.5684C45.8611 14.0258 46.1601 13.3053 46.1601 12.407C46.1601 11.5088 45.8611 10.7883 45.2631 10.2456C44.6651 9.70295 43.8989 9.4316 42.9646 9.4316H38.4796V15.3825H42.9646Z" fill="#111111" />
                                <path d="M53.1009 10.0491C52.615 10.0491 52.1852 9.87137 51.8114 9.51581C51.4564 9.16026 51.2788 8.72985 51.2788 8.22459C51.2788 7.71932 51.4564 7.28891 51.8114 6.93336C52.1852 6.5778 52.615 6.40002 53.1009 6.40002C53.6054 6.40002 54.0352 6.5778 54.3903 6.93336C54.7453 7.28891 54.9229 7.71932 54.9229 8.22459C54.9229 8.72985 54.7453 9.16026 54.3903 9.51581C54.0352 9.87137 53.6054 10.0491 53.1009 10.0491ZM54.5865 25.2632H51.6432V11.7053H54.5865V25.2632Z" fill="#111111" />
                                <path d="M67.4497 25.2632H56.7138V23.0456L63.1999 14.4L56.7138 14.2877V11.7053H67.3376V13.8667L60.5821 22.7088H67.4497V25.2632Z" fill="#111111" />
                                <path d="M80.7999 25.2632H77.8567V23.8035C76.8102 25.0012 75.3526 25.6 73.4838 25.6C72.2505 25.6 71.1573 25.207 70.2042 24.4211C69.2512 23.6164 68.7746 22.5123 68.7746 21.1088C68.7746 19.6679 69.2418 18.5731 70.1762 17.8246C71.1292 17.076 72.2318 16.7018 73.4838 16.7018C75.4086 16.7018 76.8663 17.2819 77.8567 18.4421V16.4211C77.8567 15.6351 77.567 15.0176 76.9877 14.5684C76.4084 14.1193 75.6422 13.8948 74.6892 13.8948C73.1755 13.8948 71.8393 14.4655 70.6807 15.607L69.4754 13.5579C71.0078 12.0983 72.9045 11.3684 75.1657 11.3684C76.8289 11.3684 78.1837 11.7614 79.2302 12.5474C80.2767 13.3334 80.7999 14.5778 80.7999 16.2807V25.2632ZM74.6331 23.579C76.1094 23.579 77.1839 23.1111 77.8567 22.1755V20.1263C77.1839 19.1907 76.1094 18.7228 74.6331 18.7228C73.7922 18.7228 73.1007 18.9474 72.5588 19.3965C72.0169 19.8456 71.7459 20.4351 71.7459 21.1649C71.7459 21.8948 72.0169 22.4842 72.5588 22.9334C73.1007 23.3638 73.7922 23.579 74.6331 23.579Z" fill="#111111" />
                                <path d="M96.6104 25.3031H95.8253L84.7851 10.0866V25.3031H84V8.80005H84.7851L95.8253 23.8681V8.80005H96.6104V25.3031Z" fill="#111111" />
                                <path d="M104.832 25.6C103.196 25.6 101.863 25.0062 100.833 23.8186C99.8188 22.6145 99.3118 21.1135 99.3118 19.3155C99.3118 17.5341 99.8188 16.0495 100.833 14.8619C101.863 13.6578 103.196 13.0557 104.832 13.0557C106.484 13.0557 107.817 13.6495 108.831 14.8372C109.845 16.0248 110.352 17.5176 110.352 19.3155C110.352 21.1135 109.845 22.6145 108.831 23.8186C107.817 25.0062 106.484 25.6 104.832 25.6ZM104.832 24.932C106.288 24.932 107.432 24.3877 108.267 23.299C109.117 22.1939 109.542 20.866 109.542 19.3155C109.542 17.7815 109.117 16.4702 108.267 15.3815C107.432 14.2763 106.288 13.7238 104.832 13.7238C103.393 13.7238 102.248 14.2763 101.397 15.3815C100.547 16.4702 100.121 17.7815 100.121 19.3155C100.121 20.866 100.547 22.1939 101.397 23.299C102.248 24.3877 103.393 24.932 104.832 24.932Z" fill="#111111" />
                                <path d="M117.099 25.3031H116.24L111.161 13.3526H112.02L116.682 24.4372L121.318 13.3526H122.177L117.099 25.3031Z" fill="#111111" />
                                <path d="M132 25.3031H131.264V23.8433C130.201 25.0145 128.835 25.6 127.167 25.6C126.055 25.6 125.081 25.2454 124.247 24.5361C123.43 23.8104 123.021 22.8372 123.021 21.6165C123.021 20.3959 123.43 19.431 124.247 18.7217C125.065 17.9959 126.038 17.633 127.167 17.633C128.835 17.633 130.201 18.2186 131.264 19.3897V16.6928C131.264 15.7691 130.945 15.0433 130.307 14.5155C129.669 13.9877 128.876 13.7238 127.927 13.7238C126.423 13.7238 125.155 14.3506 124.125 15.6042L123.56 15.1093C124.166 14.4 124.803 13.8805 125.474 13.5506C126.145 13.2207 126.962 13.0557 127.927 13.0557C129.138 13.0557 130.119 13.3691 130.871 13.9959C131.624 14.6062 132 15.497 132 16.6681V25.3031ZM127.363 24.932C129.048 24.932 130.348 24.3217 131.264 23.1011V20.132C130.348 18.9114 129.048 18.3011 127.363 18.3011C126.3 18.3011 125.441 18.6227 124.787 19.266C124.149 19.8928 123.83 20.6763 123.83 21.6165C123.83 22.5567 124.149 23.3485 124.787 23.9918C125.441 24.6186 126.3 24.932 127.363 24.932Z" fill="#111111" />
                            </svg>
                        </NavLink>
                    </div>

                    <div className="d-flex justify-content-end align-items-center flex-grow-1">
                        {/* <label className="search d-flex align-items-center ps-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5763 10.7406H10.0343L9.8422 10.5322C10.5146 9.65223 10.9194 8.50986 10.9194 7.26715C10.9194 4.49614 8.92281 2.25 6.45969 2.25C3.99657 2.25 2 4.49614 2 7.26715C2 10.0382 3.99657 12.2843 6.45969 12.2843C7.56432 12.2843 8.57976 11.8289 9.36192 11.0725L9.54717 11.2886V11.8984L12.9777 15.75L14 14.5999L10.5763 10.7406ZM6.4597 10.7406C4.75129 10.7406 3.37222 9.18911 3.37222 7.26716C3.37222 5.3452 4.75129 3.79374 6.4597 3.79374C8.1681 3.79374 9.54717 5.3452 9.54717 7.26716C9.54717 9.18911 8.1681 10.7406 6.4597 10.7406Z" fill="#A3A3A3" />
                            </svg>
                            <input type="text" className="form-control" placeholder='Поиск' />
                        </label> */}
                        <div className="tools d-flex align-items-center">
                            <a role='button' className='notification me-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 22C13.6 22 14.5 21.1 14.5 20H10.5C10.5 21.1 11.4 22 12.5 22ZM19 16V10.5C19 7.43 16.87 4.86 14 4.18V3.5C14 2.67 13.33 2 12.5 2C11.67 2 11 2.67 11 3.5V4.18C8.13 4.86 6 7.43 6 10.5V16L4 18V19H21V18L19 16Z" fill="#A3A3A3" />
                                </svg>
                            </a>
                            <a role='button' className='profile d-flex align-items-center'>
                                <div className="avatar">
                                    <img src="/images/avatar.jpg" />
                                </div>
                                <div>
                                    <p className="fullname">{auth.user?.fullname}</p>
                                    <p className="role">{auth.user?.role}</p>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default connect(state => ({ auth: state.auth }))(Navbar);