import React, { useEffect, useState } from 'react';

import Modal from '../modal/Modal';
import PartnerForm from '../forms/PartnerForm';
import { get } from '../../utils/request';
import { toast } from 'react-toastify';

export default function PartnerModal({ isOpen, setIsOpen, fetchModels, updateModelId, setUpdateModelId }) {
    const [isLoading, setIsLoading] = useState(true);

    const [model, setModel] = useState(null);

    const fetchModel = async () => {
        try {
            let res = await get(`partners/get?id=${updateModelId}`);

            if (res.status == "success") {
                setModel(res.model);
                setIsLoading(false);
            } else {
                setIsOpen(false);
                toast.error(res.message);
            }
        } catch (ex) {
            setIsOpen(false);
            toast.error("Ошибка при получение данных партнера");
        }
    }

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);

            if (!updateModelId) {
                setIsLoading(false);
            } else {
                fetchModel();
            }
        } else {
            setUpdateModelId(null);
            setModel(null);
        }
    }, [isOpen]);

    if (!isOpen) return;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} label={updateModelId ? "Редактирование" : "Добавить партнера"}>
            {isLoading ? (
                <div className='text-center pt-3'>
                    <i className="fa fa-3x fa-spinner rotating"></i>
                </div>
            ) : (
                <PartnerForm key={isOpen} isOpen={isOpen} setIsOpen={setIsOpen} model={model} fetchModels={fetchModels} />
            )}
        </Modal>
    );
}