import React from 'react';

export default function ModalInput({ name, defaultValue = "", required = false, type = "text", minLength = 0, label, register, errors, readOnly = false, forAll = null }) {

    return (
        <div className="form-group">
            <div className="d-flex justify-content-between">
                <label htmlFor={name}>{label}</label>
                {forAll}
            </div>
            <input type={type} readOnly={readOnly} className={errors[name]?.type} id={name} {...register(name, { required, defaultValue, minLength })} />
        </div>
    );
}