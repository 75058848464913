import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import moment from 'moment/moment';

import Pagination from '../components/Pagination';
import { deleteRequest, get } from '../utils/request';
import LimitPage from '../components/forms/LimitPage';
import SortableTableHeader from '../components/table/SortableTableHeader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ReportModal from '../components/modal/ReportModal';
import { reportStatusName } from '../utils/functions';
import { HOST_URL } from '../config';

const CancelToken = axios.CancelToken;
let cancel;

function Reports() {
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [maxCount, setMaxCount] = useState(0);
    const [type, setType] = useState(1); // 1 - report, 2 - withdraw, 3 - saved withdraws, 4 - add earns
    const [order, setOrder] = useState("created_at");
    const [orderType, setOrderType] = useState("asc");
    const [models, setModels] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`reports?limit=${limit}&page=${currentPage}&type=${type}&order=${order}&orderType=${orderType}&search=${search}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (res.models) {
            setModels(res.models);
            setMaxCount(Number.parseInt(res.maxCount));
        }

        setIsLoading(false);
    }

    const deleteReport = async (model) => {
        if (window.confirm(`Удалить ${model.filename} ?`)) {
            let res = await deleteRequest(`reports/delete`, {
                data: {
                    id: model.id
                }
            });

            if (res.status == "success") {
                toast.success(`Отчет удален`);
            }

            fetchModels();
        }
    };

    useEffect(() => {
        fetchModels();
    }, [type, limit, currentPage, order, orderType, search]);

    return (
        <>
            <Helmet>
                <title>Отчеты</title>
            </Helmet>

            <ReportModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} fetchModels={fetchModels} />
            {/* <PartnerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} updateModelId={updateModelId} setUpdateModelId={setUpdateModelId} fetchModels={fetchModels} /> */}

            <div>
                <div className="header">
                    <h1 className='title'>Отчеты</h1>

                    <div className="actions">
                        <div className="variants me-3">
                            <a role='button' className={type == 1 ? 'active' : ''} onClick={() => setType(1)}>Отчеты</a>
                            <a role='button' className={type == 2 ? 'active' : ''} onClick={() => setType(2)}>Выплата</a>
                            <a role='button' className={type == 3 ? 'active' : ''} onClick={() => setType(3)}>Сохраненные оплаты</a>
                            <a role='button' className={type == 4 ? 'active' : ''} onClick={() => setType(4)}>Доп доходы</a>
                        </div>

                        <a role='button' className="btn btn-primary ms-3" onClick={() => {
                            setIsModalOpen(true);
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clipPath="url(#clip0_71_1159)">
                                    <path d="M15.8334 10.8333H10.8334V15.8333H9.16669V10.8333H4.16669V9.16663H9.16669V4.16663H10.8334V9.16663H15.8334V10.8333Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_71_1159">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className='ms-1'>
                                Новый отчет
                            </span>
                        </a>
                    </div>
                </div>

                <div className="header mt-4">
                    <LimitPage limit={limit} setLimit={setLimit} />

                    <div className="actions">
                        <label className="search d-flex align-items-center ps-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5763 10.7406H10.0343L9.8422 10.5322C10.5146 9.65223 10.9194 8.50986 10.9194 7.26715C10.9194 4.49614 8.92281 2.25 6.45969 2.25C3.99657 2.25 2 4.49614 2 7.26715C2 10.0382 3.99657 12.2843 6.45969 12.2843C7.56432 12.2843 8.57976 11.8289 9.36192 11.0725L9.54717 11.2886V11.8984L12.9777 15.75L14 14.5999L10.5763 10.7406ZM6.4597 10.7406C4.75129 10.7406 3.37222 9.18911 3.37222 7.26716C3.37222 5.3452 4.75129 3.79374 6.4597 3.79374C8.1681 3.79374 9.54717 5.3452 9.54717 7.26716C9.54717 9.18911 8.1681 10.7406 6.4597 10.7406Z" fill="#A3A3A3" />
                            </svg>
                            <input type="text" className="form-control" placeholder='Поиск' value={search} onChange={(e) => setSearch(e.target.value)} />
                        </label>
                    </div>
                </div>

                <div className="main-table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <SortableTableHeader title={"Дата"} value={"created_at"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Файл"} value={"filename"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Статус"} value={"status"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Пользователь"} value={"admin_id"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Кол-во строк"} value={"max_rows"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Кол-во новых объектов"} value={"new_objects_count"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th className='text-end'>
                                    Действия
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.length == 0 && <tr><td className='text-center' colSpan={15}>{!isLoading ? "Ничего не найдено" : "Идет загрузка..."}</td></tr>}
                            {models.map((model, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {moment(model.created_at).format("DD.MM.YYYY HH:mm")}
                                        </td>
                                        <td>
                                            {model.filename}
                                        </td>
                                        <td>
                                            {reportStatusName(model.status)}
                                        </td>
                                        <td>
                                            {model.admin?.fullname}
                                        </td>
                                        <td>
                                            {model.success_rows} / {model.max_rows}
                                        </td>
                                        <td>
                                            {model.new_objects_count}
                                        </td>
                                        <td className='text-end'>
                                            <a className='me-2' href={`${HOST_URL}/${model.platform?.folder}/${model.filename}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="0.499939" y="0.5" width="23" height="23" rx="5.5" fill="white" />
                                                    <g clipPath="url(#clip0_84_2987)">
                                                        <path d="M16.6666 10H13.9999V6H9.99992V10H7.33325L11.9999 14.6667L16.6666 10ZM7.33325 16V17.3333H16.6666V16H7.33325Z" fill="#A3A3A3" />
                                                    </g>
                                                    <rect x="0.499939" y="0.5" width="23" height="23" rx="5.5" stroke="#C4C4C4" />
                                                    <defs>
                                                        <clipPath id="clip0_84_2987">
                                                            <rect width="16" height="16" fill="white" transform="translate(3.99994 4)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                            <a role='button' onClick={() => {
                                                deleteReport(model);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
                                                    <g clipPath="url(#clip0_637_6176)">
                                                        <path d="M8.00016 16.6667C8.00016 17.4 8.60016 18 9.3335 18H14.6668C15.4002 18 16.0002 17.4 16.0002 16.6667V8.66667H8.00016V16.6667ZM16.6668 6.66667H14.3335L13.6668 6H10.3335L9.66683 6.66667H7.3335V8H16.6668V6.66667Z" fill="#A3A3A3" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#C4C4C4" />
                                                    <defs>
                                                        <clipPath id="clip0_637_6176">
                                                            <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="header mt-4">
                    <LimitPage limit={limit} setLimit={setLimit} />
                    <Pagination currentPage={currentPage} limit={limit} onChange={(page) => setCurrentPage(page)} maxCount={maxCount} />
                </div>
            </div>
        </>
    );
}

export default Reports;