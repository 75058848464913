import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import ModalInput from './ModalInput';
import { post } from '../../utils/request';
import { toast } from 'react-toastify';
import ModalSelect from './ModalSelect';

export default function UserForm({ setIsOpen, fetchModels, model }) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: {
            fullname: model?.fullname ?? "",
            username: model?.username ?? "",
            password: "",
            status: model?.status ?? 10,
            role: model?.role ?? 1,
            // if (role == 100) return 'developer';
            // if (role == 10) return 'admin';
            // if (role == 1) return 'editor';
        }
    });

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            let res = await post(model ? `admins/update?id=${model.id}` : "admins/register", data);

            if (res.status == "success") {
                setIsOpen(false);
                toast.success(res.message);
                fetchModels && fetchModels();
            } else {
                toast.error(res.message);
            }
        } catch (ex) {
            toast.error("Произошла ошибка при добавлении")
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='py-4'>
            <ModalInput register={register} name="fullname" label={"Имя в системе"} required errors={errors} />

            <ModalInput register={register} name="username" required label={"Логин"} errors={errors} />
            <ModalSelect register={register} name="role" required label={"Роль"} options={[{ label: "Модератор", value: 1 }, { label: "Админ", value: 10 }]} errors={errors} />

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="password" required={!model} label={"Пароль"} errors={errors} />
                    <div className="text-end" style={{ marginTop: -16 }}>
                        <a role="button" onClick={() => {
                            let generated_password = (Math.random() + 1).toString(36).substring(4);

                            setValue("password", generated_password);
                        }}>генерировать</a>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
                <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    {isLoading && <i className="fa fa-spinner rotating me-2"></i>}
                    {model ? "Редактировать пользователя" : "Добавить пользователя"}
                </button>
            </div>
        </form>
    );
}