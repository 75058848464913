let prefix = 'rizanova-crm-';

const tryParseJSON = jsonString => {
    try {
        var o = JSON.parse(jsonString);

        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) { }

    return jsonString;
}

export const storeData = (key, value) => {
    try {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(prefix + key, value);
    } catch (ex) { }
}

export const getData = (key) => {
    try {
        let value = localStorage.getItem(prefix + key);

        return tryParseJSON(value);
    } catch (ex) {
        return null;
    }
}

export const removeData = (key) => {
    localStorage.removeItem(prefix + key);
}