import { forwardRef, useEffect, useState } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request.js';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export default forwardRef((props, ref) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(props.isMulti ? [] : undefined);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchValue = async () => {
            try {
                if (props.setAuthors) {
                    let res = await get(`objects/get-all?id=${props.value}`);

                    if (res.status == "success") {
                        setValue({ label: res.model.name, value: res.model.id });

                        if (res.model.user_objects?.length > 0) {
                            let author = res.model.user_objects.find(user_object => user_object.is_author);
                            let coauthors = res.model.user_objects.filter(user_object => !user_object.is_author);

                            if (author) {
                                props.setValue(`objects[${props.index}].rate`, author.rate);
                                props.setValue(`objects[${props.index}].partner_id`, author.user_id);
                            }

                            if (coauthors.length > 0) {
                                props.setValue(`objects[${props.index}].coauthors`, coauthors.map(coauthor => ({ partner_id: coauthor.user_id, rate: coauthor.rate })));
                            } else {
                                props.setValue(`objects[${props.index}].coauthors`, [{ partner_id: "", rate: "" }]);
                            }
                        }
                    }
                } else {
                    let res = await get(`objects/get?id=${props.value}`);

                    if (res.status == "success") {
                        setValue({ label: res.model.name, value: res.model.id });
                    }
                }

            } catch (ex) {
                console.log(ex);
            }
        }

        if (props.value) {
            fetchValue();
        }
    }, [props.value]);

    const handleCreate = async (inputValue) => {
        setIsLoading(true);

        let res = await post(`objects/creatable`, ({
            name: inputValue,
        }));

        if (res?.status === "success") {
            toast.success(res.message);
            let newOjbect = { value: res.model.id, label: res.model.name };
            setValue(newOjbect);
            props.onChange(newOjbect.value);
        }

        setIsLoading(false);
    }

    const loadObjects = async (inputValue, callback) => {
        cancel && cancel();

        let objects = await get(`unsorted/light?search=${inputValue}&partner_id=${props.partner_id}&platform_id=${props.platform_id}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        let options = objects.models.map(object => ({ label: object.name + (object.user_objects?.length > 0 ? " - " + object.user_objects.map(user_object => user_object.user.fullname).join(", ") : ""), value: object.id }));

        if (callback)
            callback(options);

        setOptions(options);

        return options;
    }

    const onChange = (newValue) => {
        setValue(props.isMulti ? [...newValue] : { ...newValue });
        props.onChange(newValue.value);
    };

    return <AsyncCreatable
        {...props}
        ref={ref}
        isLoading={isLoading}
        onCreateOption={handleCreate}
        formatCreateLabel={(inputValue) => `Создать объекта "${inputValue}"`}
        defaultOptions
        onChange={onChange}
        options={options}
        noOptionsMessage={({ inputValue }) => inputValue.length == 0 ? "Введите буквы" : "Пусто"}
        value={value}
        loadOptions={loadObjects}
    />;
});