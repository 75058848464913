import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ObjectFields from './objects/ObjectFields';
import { toast } from 'react-toastify';
import { post } from '../../utils/request';

const defaultValues = {
    objects: [
        {
            id: "",
            name: "",
            platforms: [{name: "", source_id: ""}],
            partner_id: "",
            rate: "",
            coauthors: [{ partner_id: "", rate: "" }]
        }
    ]
};

export default function ObjectsForm({ setIsOpen, fetchModels, models }) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        reset
    } = useForm({
        defaultValues: models?.length > 0 ? {
            objects: models.map(model => {
                let author = model.user_objects.find(user_object => user_object.is_author);
                let coauthors = model.user_objects.filter(user_object => !user_object.is_author).map(user_object => ({ partner_id: user_object.user_id, rate: user_object.rate }));

                return {
                    id: model.id,
                    name: model.name,
                    partner_id: author ? author.user_id : "",
                    rate: author ? author.rate : "",
                    platforms: model.object_platforms.map(platform => ({ name: platform.platform_id, source_id: platform.source_id })),
                    coauthors: coauthors.length > 0 ? coauthors : [{ partner_id: "", rate: "" }]
                };
            })
        } : defaultValues
    });

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);

            let res = await post(`objects/create`, data);

            if (res.status == "success") {
                toast.success(res.message);
                setIsOpen(false);
                fetchModels && fetchModels();
                reset(defaultValues);
            }
        } catch (ex) {
            toast.error("Не удалось сохранить объект");
        }

        setIsLoading(false);
        return;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='pt-4' style={{ minWidth: "584px" }}>
            <ObjectFields control={control} getValues={getValues} register={register} setValue={setValue} errors={errors} />

            <div className="d-flex justify-content-end mt-3">
                <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    {isLoading && <i className="fa fa-spinner rotating me-2"></i>}
                    {"Сохранить"}
                </button>
            </div>
        </form>
    );
}