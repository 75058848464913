import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { logout } from '../redux/reducers/auth';
import ReactDatePicker from 'react-datepicker';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';

import { Bar, Pie } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect } from 'react';
import { get } from '../utils/request';
import moment from 'moment';
import { number_format } from '../utils/functions';
import { NavLink } from 'react-router-dom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

function Home({ logout }) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [platforms, setPlatforms] = useState([]);
    const [objects, setObjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [withdraw, setWithdraw] = useState(0);
    const [add_earns, setAdd_earns] = useState(0);
    const [earns, setEarns] = useState(0);
    const [views, setViews] = useState(0);

    useEffect(() => {
        let fetchData = async () => {
            let res = await get(`account/home?start=${moment(startDate).format("YYYY-MM")}&end=${moment(endDate).format("YYYY-MM")}`);

            if (res.status == "success") {
                setPlatforms(res.platforms);
                setObjects(res.objects);
                setUsers(res.users);

                if (res.withdraw.length > 0) {
                    setWithdraw(res.withdraw[0].amount ?? 0);
                } else {
                    setWithdraw(0);
                }

                if (res.add_earns.length > 0) {
                    setAdd_earns(res.add_earns[0].amount ?? 0);
                } else {
                    setAdd_earns(0);
                }

                if (res.platforms.length > 0) {
                    setEarns(res.platforms.map(platform => platform._sum.earn).reduce((a, b) => parseFloat(a) + parseFloat(b), 0));
                    setViews(res.platforms.map(platform => platform._sum.views).reduce((a, b) => parseFloat(a) + parseFloat(b), 0));
                } else {
                    setEarns(0);
                    setViews(0);
                }
            }
        }

        if (startDate && endDate)
            fetchData();
    }, [startDate, endDate]);

    return (
        <>
            <Helmet>
                <title>Обзор</title>
            </Helmet>
            <div>
                <div className="header">
                    <h1 className='title'>Обзор</h1>

                    <div className="actions">
                        {/* <div className="variants me-3">
                            <a role='button' className='active'>Месяц</a>
                            <a role='button'>Год</a>
                        </div> */}

                        <div className="month-range-picker-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clipPath="url(#clip0_139_8556)">
                                    <path d="M16.6667 2.50001H15.8334V0.833344H14.1667V2.50001H5.83335V0.833344H4.16669V2.50001H3.33335C2.41669 2.50001 1.66669 3.25001 1.66669 4.16668V17.5C1.66669 18.4167 2.41669 19.1667 3.33335 19.1667H16.6667C17.5834 19.1667 18.3334 18.4167 18.3334 17.5V4.16668C18.3334 3.25001 17.5834 2.50001 16.6667 2.50001ZM16.6667 17.5H3.33335V6.66668H16.6667V17.5Z" fill="#111111" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_139_8556">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <ReactDatePicker
                                className='month-range-picker'
                                selected={startDate}
                                onChange={([newStartDate, newEndDate]) => {
                                    console.log(newStartDate);
                                    setStartDate(newStartDate);
                                    setEndDate(newEndDate);
                                }}
                                onCalendarClose={() => {
                                    if (startDate && !endDate) {
                                        setEndDate(startDate);
                                    }
                                }}
                                selectsRange
                                startDate={startDate}
                                locale="ru"
                                endDate={endDate}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                            />
                        </div>
                        <a role='button' className="btn btn-default ms-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clipPath="url(#clip0_139_8559)">
                                    <path d="M15.8334 7.5H12.5V2.5H7.50002V7.5H4.16669L10 13.3333L15.8334 7.5ZM4.16669 15V16.6667H15.8334V15H4.16669Z" fill="#111111" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_139_8559">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>
                                Экспорт
                            </span>
                        </a>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-title">
                                <span>{number_format(earns)} сум</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_203_8786)">
                                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.41 18.09V20H10.74V18.07C9.03 17.71 7.58 16.61 7.47 14.67H9.43C9.53 15.72 10.25 16.54 12.08 16.54C14.04 16.54 14.48 15.56 14.48 14.95C14.48 14.12 14.04 13.34 11.81 12.81C9.33 12.21 7.63 11.19 7.63 9.14C7.63 7.42 9.02 6.3 10.74 5.93V4H13.41V5.95C15.27 6.4 16.2 7.81 16.26 9.34H14.3C14.25 8.23 13.66 7.47 12.08 7.47C10.58 7.47 9.68 8.15 9.68 9.11C9.68 9.95 10.33 10.5 12.35 11.02C14.37 11.54 16.53 12.41 16.53 14.93C16.52 16.76 15.15 17.76 13.41 18.09Z" fill="#F1A239" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_203_8786">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <p>Доход</p>

                            <div className="progress-container d-flex align-items-center">
                                <div className="progress flex-grow-1">
                                    <div className="trail" style={{ width: `${100}%`, backgroundColor: "#F1A239" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-title">
                                <span>{number_format(add_earns)} сум</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_203_8786)">
                                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.41 18.09V20H10.74V18.07C9.03 17.71 7.58 16.61 7.47 14.67H9.43C9.53 15.72 10.25 16.54 12.08 16.54C14.04 16.54 14.48 15.56 14.48 14.95C14.48 14.12 14.04 13.34 11.81 12.81C9.33 12.21 7.63 11.19 7.63 9.14C7.63 7.42 9.02 6.3 10.74 5.93V4H13.41V5.95C15.27 6.4 16.2 7.81 16.26 9.34H14.3C14.25 8.23 13.66 7.47 12.08 7.47C10.58 7.47 9.68 8.15 9.68 9.11C9.68 9.95 10.33 10.5 12.35 11.02C14.37 11.54 16.53 12.41 16.53 14.93C16.52 16.76 15.15 17.76 13.41 18.09Z" fill="#F1A239" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_203_8786">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <p>Доп. доход</p>

                            <div className="progress-container d-flex align-items-center">
                                <div className="progress flex-grow-1">
                                    <div className="trail" style={{ width: `${100}%`, backgroundColor: "#49A2C4" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-title">
                                <span>{number_format(withdraw)} сум</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z" fill="#EF225D" />
                                </svg>
                            </div>

                            <p>Снято средств</p>

                            <div className="progress-container d-flex align-items-center">
                                <div className="progress flex-grow-1">
                                    <div className="trail" style={{ width: `${100}%`, backgroundColor: "#EF225D" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-title">
                                <span>{number_format(views)}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_203_8815)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.67 13.13C18.04 14.06 19 15.32 19 17V20H23V17C23 14.82 19.43 13.53 16.67 13.13Z" fill="#193F57" />
                                        <path d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z" fill="#193F57" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C14.53 4 14.09 4.1 13.67 4.24C14.5 5.27 15 6.58 15 8C15 9.42 14.5 10.73 13.67 11.76C14.09 11.9 14.53 12 15 12Z" fill="#193F57" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9 13C6.33 13 1 14.34 1 17V20H17V17C17 14.34 11.67 13 9 13Z" fill="#193F57" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_203_8815">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <p>Количество просмотров</p>

                            <div className="progress-container d-flex align-items-center">
                                <div className="progress flex-grow-1">
                                    <div className="trail" style={{ width: `${100}%`, backgroundColor: "#193F57" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row mt-4">
                    <div className="col-md-8">
                        <div className="box">
                            <div className="title">Платформы</div>
                            <div className="pt-4">
                                <Bar options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        datalabels: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        y: {
                                            grid: {
                                                display: true
                                            },
                                            max: Math.max(...platforms.map(platform => platform._sum.earn)) * 1.2,
                                            ticks: {
                                                font: {
                                                    size: 12
                                                },
                                                color: "#A3A3A3"
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false
                                            },
                                            ticks: {
                                                font: {
                                                    size: 14,
                                                },
                                                color: "#111111"
                                            }
                                        }
                                    },
                                }
                                } data={{
                                    labels: platforms.map(platform => platform.platform_id),
                                    datasets: [
                                        {
                                            label: 'Доход',
                                            data: platforms.map(platform => platform._sum.earn),
                                            backgroundColor: ['#F1A239', "#EF225D", "#193F57", "#49A2C4", "#F5C36D", "#3C86A3", "#B5B5B5", "#142E66", "#CC4264"]
                                        },
                                    ],
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="box h-100">
                            <div className="title">Тип контента</div>

                            <div className="pt-4">
                                <Pie options={{
                                    plugins: {
                                        legend: {
                                            position: "bottom",
                                            labels: {
                                                textAlign: 'center',
                                                font: {
                                                    size: 16
                                                }
                                            }
                                        },
                                        datalabels: {
                                            display: true,
                                            formatter: function (value, context) {
                                                return Math.round(value / (100) * 100) + "%";
                                            },
                                            color: 'white',
                                            font: {
                                                size: 18,
                                                weight: 600
                                            }
                                        }
                                    },
                                    layout: {
                                        padding: 48
                                    },

                                }} data={{
                                    labels: ['Видео', 'Музыка'],
                                    datasets: [
                                        {
                                            label: 'Кол-во',
                                            data: [50, 50],
                                            backgroundColor: [
                                                '#EF225D',
                                                '#F1A239',
                                            ],
                                            borderColor: [
                                                '#fff',
                                                '#fff',
                                            ],
                                            borderWidth: 3,
                                        },
                                    ],
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="box">
                            <div className="title">Популярные объекты</div>

                            <div className="d-flex justify-content-between mt-4 table-header">
                                <p>Наименование</p>
                                <p>Кол-во прослушиваний</p>
                            </div>
                            <table className='home-table'>
                                <tbody>
                                    {objects.length == 0 && <tr><td colSpan={7}>Ничего не найдено</td></tr>}
                                    {objects.map((object, index) => {
                                        return (
                                            <tr key={object.id}>
                                                <td>
                                                    <div className="order text-center">
                                                        <div>{index + 1}.</div>
                                                        {index == 0 ? (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <g clipPath="url(#clip0_137_12506)">
                                                                <path d="M14.5642 12.3077H1.74365V15.2821H14.5642V12.3077Z" fill="#FA8C00" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.57324 9.94893L8.01824 0.0699768L14.5799 9.94893H1.57324Z" fill="#FF8C00" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M14.485 11.5476L15.985 2.82358L3.22571 11.2028L14.485 11.5476Z" fill="#EDA500" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.52532 11.4948L0.0863037 2.78033L12.0038 11.4948H1.52532Z" fill="#FEC400" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_137_12506">
                                                                    <rect width="16" height="16" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <g clipPath="url(#clip0_137_12525)">
                                                                    <path d="M5.41289 10.851L9.01989 7.09126C9.56303 6.52512 10.4404 6.52512 10.9835 7.09126L14.5905 10.851C15.4679 11.7655 14.8412 13.3333 13.6018 13.3333H6.38776C5.14829 13.3333 4.53552 11.7655 5.41289 10.851Z" fill="#4FD28C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_137_12525">
                                                                        <rect width="20" height="20" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            //      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            //      <g clipPath="url(#clip0_137_12538)">
                                                            //          <path d="M14.5871 9.14898L10.9801 12.9087C10.437 13.4749 9.55959 13.4749 9.01646 12.9087L5.40946 9.14898C4.53208 8.23445 5.15878 6.66668 6.39825 6.66668L13.6122 6.66668C14.8517 6.66668 15.4645 8.23445 14.5871 9.14898Z" fill="#EF225D" />
                                                            //      </g>
                                                            //      <defs>
                                                            //          <clipPath id="clip0_137_12538">
                                                            //              <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 -1 20 20)" />
                                                            //          </clipPath>
                                                            //      </defs>
                                                            //  </svg>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* <img src="https://api.rizanova.uz/uploads/music/jpg/1681808484421.jpg" alt="" /> */}
                                                </td>
                                                <td>
                                                    <NavLink to={`/partner/${object.user_id}`} className={"text-decoration-none d-block w-100"}><div className='name'>{object.fullname}</div></NavLink>
                                                    <NavLink to={`/object/${object.id}`} className={"text-decoration-none d-block"}><div className="object">{object.name}</div></NavLink>
                                                </td>
                                                <td className='end'>
                                                    {number_format(object.views)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box">
                            <div className="title">Топ партнеры</div>

                            <div className="d-flex justify-content-between mt-4 table-header">
                                <p>Наименование</p>
                                <p>Кол-во прослушиваний</p>
                            </div>
                            <table className='home-table'>
                                <tbody>
                                    {users.length == 0 && <tr><td colSpan={7}>Ничего не найдено</td></tr>}
                                    {users.map((user, index) => {
                                        return (
                                            <tr key={user.id}>
                                                <td>
                                                    <div className="order text-center">
                                                        <div>{index + 1}.</div>
                                                        {index == 0 ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clipPath="url(#clip0_137_12506)">
                                                                    <path d="M14.5642 12.3077H1.74365V15.2821H14.5642V12.3077Z" fill="#FA8C00" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.57324 9.94893L8.01824 0.0699768L14.5799 9.94893H1.57324Z" fill="#FF8C00" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.485 11.5476L15.985 2.82358L3.22571 11.2028L14.485 11.5476Z" fill="#EDA500" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.52532 11.4948L0.0863037 2.78033L12.0038 11.4948H1.52532Z" fill="#FEC400" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_137_12506">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <g clipPath="url(#clip0_137_12525)">
                                                                    <path d="M5.41289 10.851L9.01989 7.09126C9.56303 6.52512 10.4404 6.52512 10.9835 7.09126L14.5905 10.851C15.4679 11.7655 14.8412 13.3333 13.6018 13.3333H6.38776C5.14829 13.3333 4.53552 11.7655 5.41289 10.851Z" fill="#4FD28C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_137_12525">
                                                                        <rect width="20" height="20" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            // <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            //     <g clipPath="url(#clip0_137_12538)">
                                                            //         <path d="M14.5871 9.14898L10.9801 12.9087C10.437 13.4749 9.55959 13.4749 9.01646 12.9087L5.40946 9.14898C4.53208 8.23445 5.15878 6.66668 6.39825 6.66668L13.6122 6.66668C14.8517 6.66668 15.4645 8.23445 14.5871 9.14898Z" fill="#EF225D" />
                                                            //     </g>
                                                            //     <defs>
                                                            //         <clipPath id="clip0_137_12538">
                                                            //             <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 -1 20 20)" />
                                                            //         </clipPath>
                                                            //     </defs>
                                                            // </svg>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* <img className='circle' src="https://api.rizanova.uz/uploads/music/jpg/1681808484421.jpg" alt="" /> */}
                                                </td>
                                                <td>
                                                    <NavLink to={`/partner/${user.id}`} className={"text-decoration-none"}><div className='name'>{user.fullname}</div></NavLink>
                                                </td>
                                                <td className='end'>
                                                    {number_format(user.views)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default connect(undefined, { logout })(Home);