import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import moment from 'moment/moment';

import Checkbox from '../components/forms/Checkbox';
import Pagination from '../components/Pagination';
import { deleteRequest, get, post } from '../utils/request';
import LimitPage from '../components/forms/LimitPage';
import SortableTableHeader from '../components/table/SortableTableHeader';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ObjectModal from '../components/modal/ObjectModal';
import { connect } from 'react-redux';
import { platformToImages } from '../utils/functions';

const CancelToken = axios.CancelToken;
let cancel;

function Objects({ platforms }) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [maxCount, setMaxCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [type, setType] = useState(1); // 1 - artist, 2 - producer, director, 3 - yur litso
    const [order, setOrder] = useState("name");
    const [orderType, setOrderType] = useState("asc");
    const [models, setModels] = useState([]);
    const [partner, setPartner] = useState(null);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ids, setIds] = useState([]);

    const navigate = useNavigate();

    const { partner_id = "" } = useParams();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`objects?limit=${limit}&page=${currentPage}&type=${type}&order=${order}&orderType=${orderType}&partner_id=${partner_id}&search=${search}${startDate ? `&start=${moment(startDate).format("YYYY-MM")}` : ``}${endDate ? `&end=${moment(endDate).format("YYYY-MM")}` : ``}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (res.models) {
            setIds([]);
            setModels(res.models);
            setMaxCount(Number.parseInt(res.maxCount));

            if (res.partner) {
                setPartner(res.partner);
            }
        }

        setIsLoading(false);
    }

    const handleDelete = useCallback(async () => {
        if (ids.length > 0) {
            if (window.confirm("Удалить выбранных объектов ?")) {
                let res = await deleteRequest(`objects/delete`, {
                    data: {
                        ids
                    }
                });

                if (res.status == "success") {
                    toast.success(`${res.deleted.count} объектов удалены`);
                }

                fetchModels();
            }
        } else {
            toast.error("Выберите объектов");
        }
    }, [ids]);

    const sendToUnsorted = useCallback(async () => {
        if (ids.length > 0) {
            if (window.confirm("Переместить выбранных в несортированные ?")) {
                let res = await post(`objects/send-to-unsorted`, {
                    ids
                });

                if (res.status == "success") {
                    toast.success(`${res.updated.count} объекты перемещены`);
                }

                fetchModels();
            }
        } else {
            toast.error("Выберите объектов");
        }
    }, [ids]);

    const deleteObject = async (model) => {
        if (model) {
            if (window.confirm(`Удалить объект "${model.name}"?`)) {
                let res = await deleteRequest(`objects/delete`, {
                    data: {
                        ids: [model.id]
                    }
                });

                if (res.status == "success") {
                    toast.success(`${res.deleted.count} объекты удалены`);
                }

                fetchModels();
            }
        } else {
            toast.error("Выберите объекта");
        }
    };

    useEffect(() => {
        fetchModels();
    }, [type, limit, currentPage, startDate, endDate, order, orderType, search]);

    return (
        <>
            <Helmet>
                <title>Объекты</title>
            </Helmet>

            <ObjectModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} updateModelIds={ids} setUpdateModelIds={setIds} fetchModels={fetchModels} />

            <div>
                <div className="header">
                    <h1 className='title'>{partner ? (<><NavLink to={`/partner/${partner.id}`}> {partner.fullname} </NavLink> <span className='mx-2'> - </span></>) : ""} Объекты</h1>

                    <div className="actions">
                        {/* <div className="me-3">
                            <a role='button' className="btn btn-primary ms-3" onClick={() => {
                                setIsModalOpen(true);
                            }}>
                                <span>
                                    Создать отчет
                                </span>
                            </a>
                        </div> */}

                        <div className="month-range-picker-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clipPath="url(#clip0_139_8556)">
                                    <path d="M16.6667 2.50001H15.8334V0.833344H14.1667V2.50001H5.83335V0.833344H4.16669V2.50001H3.33335C2.41669 2.50001 1.66669 3.25001 1.66669 4.16668V17.5C1.66669 18.4167 2.41669 19.1667 3.33335 19.1667H16.6667C17.5834 19.1667 18.3334 18.4167 18.3334 17.5V4.16668C18.3334 3.25001 17.5834 2.50001 16.6667 2.50001ZM16.6667 17.5H3.33335V6.66668H16.6667V17.5Z" fill="#111111" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_139_8556">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <ReactDatePicker
                                className='month-range-picker'
                                selected={startDate}
                                onChange={([newStartDate, newEndDate]) => {
                                    setStartDate(newStartDate);
                                    setEndDate(newEndDate);
                                }}
                                onCalendarClose={() => {
                                    if (startDate && !endDate) {
                                        setEndDate(startDate);
                                    }
                                }}
                                selectsRange
                                startDate={startDate}
                                locale="ru"
                                endDate={endDate}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                            />
                        </div>
                        <a role='button' className="btn btn-default ms-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clipPath="url(#clip0_139_8559)">
                                    <path d="M15.8334 7.5H12.5V2.5H7.50002V7.5H4.16669L10 13.3333L15.8334 7.5ZM4.16669 15V16.6667H15.8334V15H4.16669Z" fill="#111111" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_139_8559">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>
                                Экспорт
                            </span>
                        </a>
                    </div>
                </div>

                <div className="header mt-4">
                    <div className="d-flex">
                        <LimitPage limit={limit} setLimit={setLimit} />
                        <label className="search d-flex align-items-center ps-2 ms-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5763 10.7406H10.0343L9.8422 10.5322C10.5146 9.65223 10.9194 8.50986 10.9194 7.26715C10.9194 4.49614 8.92281 2.25 6.45969 2.25C3.99657 2.25 2 4.49614 2 7.26715C2 10.0382 3.99657 12.2843 6.45969 12.2843C7.56432 12.2843 8.57976 11.8289 9.36192 11.0725L9.54717 11.2886V11.8984L12.9777 15.75L14 14.5999L10.5763 10.7406ZM6.4597 10.7406C4.75129 10.7406 3.37222 9.18911 3.37222 7.26716C3.37222 5.3452 4.75129 3.79374 6.4597 3.79374C8.1681 3.79374 9.54717 5.3452 9.54717 7.26716C9.54717 9.18911 8.1681 10.7406 6.4597 10.7406Z" fill="#A3A3A3" />
                            </svg>
                            <input type="text" className="form-control" placeholder='Поиск' value={search} onChange={(e) => setSearch(e.target.value)} />
                        </label>
                    </div>

                    <div className="actions">


                        {/* <a role='button' className="btn btn-default ms-3">Категории</a> */}
                        <a role='button' className="btn btn-default ms-3" onClick={() => {
                            handleDelete();
                        }}>Удалить</a>
                        <a role='button' className="btn btn-primary ms-3" onClick={() => {
                            sendToUnsorted();
                        }}>
                            В несортированные
                        </a>
                        <a role='button' className="btn btn-primary ms-3" onClick={() => {
                            setIsModalOpen(true);
                        }}>
                            {ids.length == 0 ? (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clipPath="url(#clip0_71_1159)">
                                            <path d="M15.8334 10.8333H10.8334V15.8333H9.16669V10.8333H4.16669V9.16663H9.16669V4.16663H10.8334V9.16663H15.8334V10.8333Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_71_1159">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span className='ms-1'>
                                        Добавить
                                    </span>
                                </>
                            ) : "Редактировать объектов"}
                        </a>
                    </div>
                </div>

                <div className="main-table">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>
                                    <Checkbox checked={models.length > 0 && ids.length == models.length} onChange={() => {
                                        if (ids.length != models.length) {
                                            setIds(models.map((model) => model.id));
                                        } else {
                                            setIds([]);
                                        }
                                    }} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"ID"} value={"id"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Название"} value={"name"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Партнер"} value={"fullname"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Со-автор"} value={"co-author"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>

                                <th>
                                    <SortableTableHeader title={"Просмотры"} value={"views"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Доход"} value={"earn"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>Площадка</th>
                                <th className='text-end'>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.length == 0 && <tr><td className='text-center' colSpan={15}>{!isLoading ? "Ничего не найдено" : "Идет загрузка..."}</td></tr>}
                            {models.map((model, index) => {
                                let partner = model.user_objects.find(user_object => user_object.is_author)?.user;

                                let coauthors = model.user_objects.filter(user_object => !user_object.is_author).map(user_object => user_object.user);
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Checkbox checked={!!ids.find(id => id == model.id)} onClick={(e) => {
                                                let lastIndex = models.findIndex((model) => model.id == ids[ids.length - 1]);

                                                if (
                                                    e.target.checked
                                                ) {
                                                    if (e.shiftKey && lastIndex > -1) {
                                                        setIds([
                                                            ...ids,
                                                            ...models.filter((model, ii) => lastIndex < index ? ii >= lastIndex && ii <= index : ii >= index && ii <= lastIndex).map((model) => model.id)
                                                        ]);
                                                    } else {
                                                        setIds([
                                                            ...ids,
                                                            model.id,
                                                        ]);
                                                    }
                                                } else {
                                                    setIds(ids.filter(id => id != model.id));
                                                }
                                            }} />
                                        </td>
                                        <td>
                                            {model.id}
                                        </td>
                                        <td>
                                            {model.name}
                                        </td>
                                        <td>
                                            {partner && <NavLink className={"link"} to={`/partner/${partner.id}`}>{partner?.fullname}</NavLink>}
                                        </td>
                                        <td>
                                            {coauthors.map(coauthor => coauthor.fullname).join(", ")}
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>{platformToImages(platforms, model.platforms)}</td>
                                        <td className='text-end'>
                                            <a className='me-2' role='button' onClick={() => {
                                                setIds([model.id]);
                                                setIsModalOpen(true);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
                                                    <g clipPath="url(#clip0_719_2042)">
                                                        <path d="M6 15.5V18H8.5L15.8733 10.6266L13.3733 8.12662L6 15.5ZM17.8067 8.69329C18.0667 8.43329 18.0667 8.01329 17.8067 7.75329L16.2467 6.19329C15.9867 5.93329 15.5667 5.93329 15.3067 6.19329L14.0867 7.41329L16.5867 9.91329L17.8067 8.69329Z" fill="#A3A3A3" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#C4C4C4" />
                                                    <defs>
                                                        <clipPath id="clip0_719_2042">
                                                            <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                            <a className='me-2' role='button' onClick={() => {
                                                navigate(`/object/${model.id}`);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
                                                    <g clipPath="url(#clip0_646_8650)">
                                                        <path d="M16.6667 5.33333H13.88C13.6 4.56 12.8667 4 12 4C11.1333 4 10.4 4.56 10.12 5.33333H7.33333C6.6 5.33333 6 5.93333 6 6.66667V17.3333C6 18.0667 6.6 18.6667 7.33333 18.6667H16.6667C17.4 18.6667 18 18.0667 18 17.3333V6.66667C18 5.93333 17.4 5.33333 16.6667 5.33333ZM12 5.33333C12.3667 5.33333 12.6667 5.63333 12.6667 6C12.6667 6.36667 12.3667 6.66667 12 6.66667C11.6333 6.66667 11.3333 6.36667 11.3333 6C11.3333 5.63333 11.6333 5.33333 12 5.33333ZM16.6667 17.3333H7.33333V6.66667H8.66667V8.66667H15.3333V6.66667H16.6667V17.3333Z" fill="#A3A3A3" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#C4C4C4" />
                                                    <defs>
                                                        <clipPath id="clip0_646_8650">
                                                            <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                            <a role='button' onClick={() => {
                                                deleteObject(model);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
                                                    <g clipPath="url(#clip0_637_6176)">
                                                        <path d="M8.00016 16.6667C8.00016 17.4 8.60016 18 9.3335 18H14.6668C15.4002 18 16.0002 17.4 16.0002 16.6667V8.66667H8.00016V16.6667ZM16.6668 6.66667H14.3335L13.6668 6H10.3335L9.66683 6.66667H7.3335V8H16.6668V6.66667Z" fill="#A3A3A3" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#C4C4C4" />
                                                    <defs>
                                                        <clipPath id="clip0_637_6176">
                                                            <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="header mt-4">
                    <LimitPage limit={limit} setLimit={setLimit} />
                    <Pagination currentPage={currentPage} limit={limit} onChange={(page) => setCurrentPage(page)} maxCount={maxCount} />
                </div>
            </div>
        </>
    );
}

export default connect(state => ({ platforms: state.platforms }))(Objects);