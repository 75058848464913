import React from 'react';
import ReactPaginate from 'react-paginate';

export default function Pagination({ maxCount, currentPage, limit, onChange }) {

    const paginate = ({ selected }) => {
        onChange && onChange(selected + 1);
    };

    return (
        <ReactPaginate
            onPageChange={paginate}
            forcePage={parseInt(currentPage - 1)}
            pageCount={Math.ceil(maxCount / limit)}
            marginPagesDisplayed={7}
            pageRangeDisplayed={5}
            previousLabel={
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_120_16890)">
                        <path d="M15.4102 7.41L10.8302 12L15.4102 16.59L14.0002 18L8.00016 12L14.0002 6L15.4102 7.41Z" fill="#111111" />
                    </g>
                    <defs>
                        <clipPath id="clip0_120_16890">
                            <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)" />
                        </clipPath>
                    </defs>
                </svg>
            }
            nextLabel={
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_120_16901)">
                        <path d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z" fill="#111111" />
                    </g>
                    <defs>
                        <clipPath id="clip0_120_16901">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
        />
    );
}