import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { post } from '../../utils/request';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { arrayToFormData } from '../../utils/functions';

const currencies = ['uzs', 'usd', 'rub'];

export default function ReportForm({ setIsOpen, fetchModels }) {
    const [isLoading, setIsLoading] = useState(false);

    const platforms = useSelector(state => state.platforms);
    const [selectedPlatform, setSelectedPlatform] = useState();
    const [currency, setCurrency] = useState("uzs");
    const [exchangeRate, setExchangeRate] = useState(1);
    const [file, setFile] = useState();

    const ref = useRef();

    const [step, setStep] = useState("platform");

    const onSubmit = async () => {
        console.log('clicked');
        if (!exchangeRate) {
            toast.error("Введите курс");
            return;
        }

        if (!file) {
            toast.error("Выберите файл отчета");
            return;
        }

        if (isLoading) return;

        setIsLoading(true);

        try {
            let res = await post(`reports/create?platform_id=${selectedPlatform}`, arrayToFormData({
                file,
                exchangeRate,
                currency,
                platform_id: selectedPlatform
            }));

            if (res.status == "success") {
                setIsOpen(false);
                toast.success(res.message);
                fetchModels && fetchModels();
            } else {
                toast.error(res.message);
            }
        } catch (ex) {
            toast.error("Произошла ошибка при добавлении")
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (currency == "uzs") {
            setExchangeRate(1);
        }
    }, [currency]);

    return (
        <>
            <div className="d-flex justify-content-end" style={{ marginTop: "-30px" }}>
                <a role="button" onClick={() => {
                    if (!isLoading)
                        setIsOpen(false);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <g clipPath="url(#clip0_120_17066)">
                            <path d="M25.3327 8.54663L23.4527 6.66663L15.9993 14.12L8.54602 6.66663L6.66602 8.54663L14.1193 16L6.66602 23.4533L8.54602 25.3333L15.9993 17.88L23.4527 25.3333L25.3327 23.4533L17.8793 16L25.3327 8.54663Z" fill="#111111" />
                        </g>
                        <defs>
                            <clipPath id="clip0_120_17066">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
            <div className='py-4'>
                {step == "platform" ? (
                    <>
                        <div className="row reports">
                            {platforms.map(platform => {
                                return (
                                    <div key={platform.id} className="col-md-6" onClick={() => {
                                        setSelectedPlatform(platform.id);
                                    }}>
                                        <a className={`cursor-pointer ${selectedPlatform == platform.id ? 'active' : ''}`} title={platform.id}>
                                            <img src={platform.big_image} alt={platform.id} />
                                            {selectedPlatform == platform.id && isLoading && <div className='loader'>
                                                <i className="fa fa-3x fa-spinner rotating me-2"></i>
                                            </div>}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                        <button type='button' className={`btn btn-primary`} disabled={!selectedPlatform} onClick={() => {
                            setStep('upload');
                        }}>Следующий шаг</button>
                    </>
                ) : (
                    <>
                        <div className="row reports">
                            {platforms.filter(p => p.id == selectedPlatform).map(platform => {
                                return (
                                    <div key={platform.id} className="col-md-6" onClick={() => {
                                        setSelectedPlatform(platform.id);
                                    }}>
                                        <a className={`cursor-pointer ${selectedPlatform == platform.id ? 'active' : ''}`} title={platform.id}>
                                            <img src={platform.big_image} alt={platform.id} />
                                            {selectedPlatform == platform.id && isLoading && <div className='loader'>
                                                <i className="fa fa-3x fa-spinner rotating me-2"></i>
                                            </div>}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="mb-3">
                            <select disabled={isLoading} value={currency} className="form-control default" onChange={(e) => {
                                setCurrency(e.target.value);
                            }}>
                                {currencies.map(c => {
                                    return <option value={c} key={c}>{c}</option>
                                })}
                            </select>
                        </div>

                        {currency != "uzs" && <div className="mb-3">
                            <label htmlFor="exchangeRate">Курс {currency.toUpperCase()} ➾ UZS</label>
                            <input disabled={isLoading} type="text" id='exchangeRate' value={exchangeRate} className='form-control' onChange={(e) => {
                                let n = e.target.value.replace(/\D/g, '');
                                setExchangeRate(n ? Number.parseFloat(n) : "");
                            }} />
                        </div>}

                        <input type="file" disabled={isLoading} ref={ref} accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' onChange={(e) => {
                            if (e.target.files.length > 0) {
                                setFile(e.target.files[0]);
                            }
                        }} />

                        <div className="mt-4">
                            <button type='button' className={`btn btn-default me-3`} disabled={!selectedPlatform || isLoading} onClick={() => {
                                setStep('platform');
                            }}>Назад</button>
                            <button type='button' className={`btn btn-primary`} disabled={isLoading} onClick={() => {
                                onSubmit();
                            }}>Загрузить</button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}