import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const platformsSlice = createSlice({
    name: "progress",
    initialState,
    reducers: {
        setPlatforms(state, action) {
            state = action.payload;

            return action.payload;
        },

    }
});

export const { setPlatforms } = platformsSlice.actions;

export default platformsSlice.reducer;