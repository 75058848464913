import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { get } from '../utils/request';
import moment from 'moment';
import { toast } from 'react-toastify';
import ObjectModal from '../components/modal/ObjectModal';
import { number_format } from '../utils/functions';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

export default function ObjectView() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [model, setModel] = useState();
    const [platforms, setPlatforms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [type, setType] = useState('earn');

    const fetchModel = async () => {
        setIsLoading(true);

        try {
            let res = await get(`objects/get-detail?id=${id}&start=${moment(startDate).format("YYYY-MM")}&end=${moment(endDate).format("YYYY-MM")}`);

            if (res.status == "success") {
                setModel(res.model);
                setPlatforms(res.platforms);
            }
        } catch (ex) {
            toast.error("Ошибка при получение данных");
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (id && startDate && endDate)
            fetchModel();
    }, [id, startDate, endDate]);

    if (isLoading) {
        return <div style={{ width: "100%", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-4x text-dark"></i>
        </div>
    }

    let partner = model.user_objects.find(user_object => user_object.is_author);

    let coauthors = model.user_objects.filter(user_object => !user_object.is_author).map(user_object => user_object.user);

    return (
        <div className='partner'>
            <ObjectModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} updateModelIds={[id]} setUpdateModelIds={() => { }} fetchModels={fetchModel} />

            <Helmet>
                <title>Объекты - {model?.name}</title>
            </Helmet>

            <div className="header">
                <h1 className='title'>Объекты - {model?.name}</h1>

                <div className="actions">
                    <div className="month-range-picker-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_139_8556)">
                                <path d="M16.6667 2.50001H15.8334V0.833344H14.1667V2.50001H5.83335V0.833344H4.16669V2.50001H3.33335C2.41669 2.50001 1.66669 3.25001 1.66669 4.16668V17.5C1.66669 18.4167 2.41669 19.1667 3.33335 19.1667H16.6667C17.5834 19.1667 18.3334 18.4167 18.3334 17.5V4.16668C18.3334 3.25001 17.5834 2.50001 16.6667 2.50001ZM16.6667 17.5H3.33335V6.66668H16.6667V17.5Z" fill="#111111" />
                            </g>
                            <defs>
                                <clipPath id="clip0_139_8556">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <ReactDatePicker
                            className='month-range-picker'
                            selected={startDate}
                            onChange={([newStartDate, newEndDate]) => {
                                console.log(newStartDate);
                                setStartDate(newStartDate);
                                setEndDate(newEndDate);
                            }}
                            onCalendarClose={() => {
                                if (startDate && !endDate) {
                                    setEndDate(startDate);
                                }
                            }}
                            selectsRange
                            startDate={startDate}
                            locale="ru"
                            endDate={endDate}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    <a role='button' className="btn btn-default ms-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_139_8559)">
                                <path d="M15.8334 7.5H12.5V2.5H7.50002V7.5H4.16669L10 13.3333L15.8334 7.5ZM4.16669 15V16.6667H15.8334V15H4.16669Z" fill="#111111" />
                            </g>
                            <defs>
                                <clipPath id="clip0_139_8559">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Экспорт
                        </span>
                    </a>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-4">
                    <div className="box">
                        {/* <div className="poster">
                            <img src="https://api.rizanova.uz/uploads/video/jpg/1685615103310.jpg" />
                        </div> */}
                        {partner && <div className="info d-flex align-items-center">
                            {/* <img src="https://api.rizanova.uz/uploads/person/jpg/1648280969221.jpg" alt="" /> */}
                            <div className="fullname">
                                <NavLink to={`/partner/${partner.user.id}`} className={"text-decoration-none link"}><h3>{partner.user.fullname}</h3></NavLink>
                                {/* <p>3:21</p> */}
                            </div>
                        </div>}
                        <div className="detail">
                            <div className="d-flex mb-3 justify-content-between">
                                <div className='label'>Дата добавления:</div>
                                <div className='value'>{moment(model.created_at).format("DD.MM.YYYY")}</div>
                            </div>
                            {coauthors.length > 0 && <div className="d-flex mb-3 justify-content-between">
                                <div className='label'>Со-автор:</div>
                                <div className='value'>{coauthors.map(coauthor => {
                                    return (
                                        <NavLink to={`/partner/${coauthor.id}`} className={"link"}>{coauthor.fullname}</NavLink>
                                    );
                                })}</div>
                            </div>}
                            <div className="d-flex mb-3 justify-content-between">
                                <div className='label'>Площадки:</div>
                                <div className='value ps-2'>
                                    <div className="places flex-wrap">
                                        {model.object_platforms.map(object_platform => {
                                            return <span className='platform-badge mr-1 mb-2' key={object_platform.id}><img src={object_platform.platform.image} style={{ height: 18 }} /> {object_platform.source_id}</span>
                                        })}
                                    </div>
                                </div>
                            </div>
                            {partner && <div className="d-flex mb-3 justify-content-between">
                                <div className='label'>Ставка:</div>
                                <div className='value'>
                                    {partner.rate}%
                                </div>
                            </div>}
                        </div>

                        <div className="actions">
                            <a role="button" className='edit' onClick={() => {
                                setIsModalOpen(true);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <g clipPath="url(#clip0_97_3972)">
                                        <path d="M3 14.375V17.5H6.125L15.3417 8.28334L12.2167 5.15834L3 14.375ZM17.7583 5.86667C18.0833 5.54167 18.0833 5.01667 17.7583 4.69167L15.8083 2.74167C15.4833 2.41667 14.9583 2.41667 14.6333 2.74167L13.1083 4.26667L16.2333 7.39167L17.7583 5.86667V5.86667Z" fill="#F1A239" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_97_3972">
                                            <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Редактировать
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="box earn-chart">
                        <div className="nav text-center">
                            <a role="button" className={type == "earn" ? 'active' : ""} onClick={() => setType("earn")}>
                                <div className='text'>Общий доход (сум)</div>
                                <div className='earn'>{platforms.length == 0 ? 0 : number_format(platforms.map(platform => platform._sum.earn).reduce((a, b) => parseFloat(a) + parseFloat(b), 0))}</div>
                                {/* <div className='difference green'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <g clipPath="url(#clip0_97_3982)">
                                            <path d="M7.5 14L12.5 9L17.5 14H7.5Z" fill="#4FD28C" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_97_3982">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>
                                        240 000
                                    </span>
                                </div> */}
                            </a>
                            <a role="button" className={type == "views" ? 'active' : ""} onClick={() => setType("views")}>
                                <div className='text'>Всего просмотров</div>
                                <div className='earn'>{platforms.length == 0 ? 0 : number_format(platforms.map(platform => platform._sum.views).reduce((a, b) => parseFloat(a) + parseFloat(b), 0))}</div>
                                {/* <div className='difference red'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <g clipPath="url(#clip0_97_3988)">
                                            <path d="M17.5 10L12.5 15L7.5 10L17.5 10Z" fill="#EF225D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_97_3988">
                                                <rect width="24" height="24" fill="white" transform="translate(24.5 24) rotate(-180)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>
                                        7 112 000
                                    </span>
                                </div> */}
                            </a>
                        </div>

                        <div className="chart">
                            <Bar options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    datalabels: {
                                        display: false
                                    }
                                },
                                scales: {
                                    y: {
                                        grid: {
                                            display: true
                                        },
                                        max: Math.max(...platforms.map(platform => platform._sum[type])) * 1.2,
                                        ticks: {
                                            font: {
                                                size: 12
                                            },
                                            color: "#A3A3A3"
                                        }
                                    },
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            font: {
                                                size: 14,
                                            },
                                            color: "#111111"
                                        }
                                    }
                                },
                            }
                            } data={{
                                labels: platforms.map(platform => platform.platform_id),
                                datasets: [
                                    {
                                        label: 'Доход за текущий месяц',
                                        data: platforms.map(platform => platform._sum[type]),
                                        backgroundColor: ['#F1A239', "#EF225D", "#193F57", "#49A2C4", "#F5C36D", "#3C86A3", "#B5B5B5", "#142E66", "#CC4264", '#F1A239', "#EF225D", "#193F57", "#49A2C4", "#F5C36D", "#3C86A3", "#B5B5B5", "#142E66", "#CC4264"]
                                    },
                                ],
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}