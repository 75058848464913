import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import Checkbox from '../components/forms/Checkbox';
import Pagination from '../components/Pagination';
import { deleteRequest, get } from '../utils/request';
import LimitPage from '../components/forms/LimitPage';
import SortableTableHeader from '../components/table/SortableTableHeader';
import { toast } from 'react-toastify';
import { formatDate } from '../utils/functions';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import TransactionModal from '../components/modal/TransactionModal';

const CancelToken = axios.CancelToken;
let cancel;

function Transactions() {
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [maxCount, setMaxCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [order, setOrder] = useState("fullname");
    const [orderType, setOrderType] = useState("asc");
    const [models, setModels] = useState([]);
    const [partner, setPartner] = useState();
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ids, setIds] = useState([]);

    const navigate = useNavigate();
    const { partner_id } = useParams();

    const [updateModelId, setUpdateModelId] = useState(null);

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`transactions?user_id=${partner_id}limit=${limit}&page=${currentPage}&order=${order}&orderType=${orderType}&search=${search}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (res.models) {
            setIds([]);
            setModels(res.models);

            if (res.user) {
                setPartner(res.user);
            }
            setMaxCount(Number.parseInt(res.maxCount));
        }

        setIsLoading(false);
    }

    const handlePayment = async (value) => {
        if (window.confirm(value == "lock" ? "Приостановить платежи?" : "Возобновить платежи?")) {
            let res = await get(`transactions/handle-payment?user_id=${partner_id}&value=${value}`);

            toast.dismiss("payment1");
            toast.dismiss("payment2");

            if (res.status == "unlocked") {
                toast.success(`Платеж возобновлен`, { toastId: "payment1" });
                setPartner(res.user);
            } else {
                toast.info(`Платеж приостановлен`, { toastId: "payment2" });
                setPartner(res.user);
            }
        }
    }

    const handleDelete = useCallback(async () => {
        if (ids.length > 0) {
            if (window.confirm("Удалить выбранных транзакций ?")) {
                let res = await deleteRequest(`partners/delete`, {
                    data: {
                        ids
                    }
                });

                if (res.status == "success") {
                    toast.success(`${res.deleted.count} транзакции удалены`);
                }

                fetchModels();
            }
        } else {
            toast.error("Выберите транзакций");
        }
    }, [ids]);

    useEffect(() => {
        fetchModels();
    }, [limit, currentPage, order, orderType, search]);

    if (isLoading) {
        return <div style={{ width: "100%", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-4x text-dark"></i>
        </div>
    }

    return (
        <>
            <Helmet>
                <title>Транзакции</title>
            </Helmet>

            <TransactionModal isOpen={isModalOpen} partner={partner} setIsOpen={setIsModalOpen} updateModelId={updateModelId} setUpdateModelId={setUpdateModelId} fetchModels={fetchModels} />

            <div>
                <div className="header">
                    <h1 className='title'>{partner ? (<><NavLink to={`/partner/${partner.id}`}> {partner.fullname} </NavLink> <span className='mx-2'> - </span></>) : ""} Транзакции</h1>
                </div>

                <div className="header mt-4">
                    <LimitPage limit={limit} setLimit={setLimit} />

                    <div className="actions">
                        <label className="search d-flex align-items-center ps-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5763 10.7406H10.0343L9.8422 10.5322C10.5146 9.65223 10.9194 8.50986 10.9194 7.26715C10.9194 4.49614 8.92281 2.25 6.45969 2.25C3.99657 2.25 2 4.49614 2 7.26715C2 10.0382 3.99657 12.2843 6.45969 12.2843C7.56432 12.2843 8.57976 11.8289 9.36192 11.0725L9.54717 11.2886V11.8984L12.9777 15.75L14 14.5999L10.5763 10.7406ZM6.4597 10.7406C4.75129 10.7406 3.37222 9.18911 3.37222 7.26716C3.37222 5.3452 4.75129 3.79374 6.4597 3.79374C8.1681 3.79374 9.54717 5.3452 9.54717 7.26716C9.54717 9.18911 8.1681 10.7406 6.4597 10.7406Z" fill="#A3A3A3" />
                            </svg>
                            <input type="text" className="form-control" placeholder='Поиск' value={search} onChange={(e) => setSearch(e.target.value)} />
                        </label>

                        <a role='button' className="btn btn-default ms-3 d-flex align-items-center" onClick={() => handlePayment(partner?.payment_blocked == 1 ? "unlock" : "lock")}>
                            {partner?.payment_blocked ? (<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_9_8114)">
                                        <path d="M12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17ZM18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6H8.9C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM18 20H6V10H18V20Z" fill="#323232" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_9_8114">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span className='ms-1'>
                                    Возобновить платеж
                                </span>
                            </>) : (<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g clipPath="url(#clip0_98_4253)">
                                        <path d="M14.9997 6.66671H14.1663V5.00004C14.1663 2.70004 12.2997 0.833374 9.99967 0.833374C7.69967 0.833374 5.83301 2.70004 5.83301 5.00004V6.66671H4.99967C4.08301 6.66671 3.33301 7.41671 3.33301 8.33337V16.6667C3.33301 17.5834 4.08301 18.3334 4.99967 18.3334H14.9997C15.9163 18.3334 16.6663 17.5834 16.6663 16.6667V8.33337C16.6663 7.41671 15.9163 6.66671 14.9997 6.66671ZM9.99967 14.1667C9.08301 14.1667 8.33301 13.4167 8.33301 12.5C8.33301 11.5834 9.08301 10.8334 9.99967 10.8334C10.9163 10.8334 11.6663 11.5834 11.6663 12.5C11.6663 13.4167 10.9163 14.1667 9.99967 14.1667ZM12.583 6.66671H7.41634V5.00004C7.41634 3.57504 8.57467 2.41671 9.99967 2.41671C11.4247 2.41671 12.583 3.57504 12.583 5.00004V6.66671Z" fill="#111111" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_98_4253">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span className='ms-1'>
                                    Приостановите платеж
                                </span>
                            </>)}
                        </a>
                        <a role='button' className="btn btn-default ms-3" onClick={() => {
                            handleDelete();
                        }}>Удалить</a>
                        <a role='button' className="btn btn-primary ms-3" onClick={() => {
                            setIsModalOpen(true);
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clipPath="url(#clip0_71_1159)">
                                    <path d="M15.8334 10.8333H10.8334V15.8333H9.16669V10.8333H4.16669V9.16663H9.16669V4.16663H10.8334V9.16663H15.8334V10.8333Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_71_1159">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className='ms-1'>
                                Добавить
                            </span>
                        </a>
                    </div>
                </div>

                <div className="main-table">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>
                                    <Checkbox checked={models.length > 0 && ids.length == models.length} onChange={() => {
                                        if (ids.length != models.length) {
                                            setIds(models.map((model) => model.id));
                                        } else {
                                            setIds([]);
                                        }
                                    }} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Дата"} value={"date"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Сумма"} value={"amount"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Источник / Название"} value={"route"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Коммент"} value={"comment"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    Способ оплаты
                                </th>
                                <th className='text-end'>
                                    Действия
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.length == 0 && <tr><td className='text-center' colSpan={15}>{!isLoading ? "Ничего не найдено" : "Идет загрузка..."}</td></tr>}
                            {models.map((model, index) => {
                                return (
                                    <tr key={index} className='cursor-pointer' onClick={(e) => {
                                        if (e.target.nodeName != "INPUT") {
                                            // setUpdateModelId(model.id);
                                            // setIsModalOpen(true);
                                            navigate(`/partner/${model.id}`);
                                        }
                                    }}>
                                        <td>
                                            <Checkbox checked={!!ids.find(id => id == model.id)} onClick={(e) => {
                                                let lastIndex = models.findIndex((model) => model.id == ids[ids.length - 1]);

                                                if (
                                                    e.target.checked
                                                ) {
                                                    if (e.shiftKey && lastIndex > -1) {
                                                        setIds([
                                                            ...ids,
                                                            ...models.filter((model, ii) => lastIndex < index ? ii >= lastIndex && ii <= index : ii >= index && ii <= lastIndex).map((model) => model.id)
                                                        ]);
                                                    } else {
                                                        setIds([
                                                            ...ids,
                                                            model.id,
                                                        ]);
                                                    }
                                                } else {
                                                    setIds(ids.filter(id => id != model.id));
                                                }
                                            }} />
                                        </td>
                                        <td>
                                            {formatDate(model.date)}
                                        </td>
                                        <td>
                                            {model.amount}
                                        </td>
                                        <td>
                                            {model.route}
                                        </td>
                                        <td>
                                            {model.comment}
                                        </td>
                                        <td>
                                            {model.route}
                                        </td>
                                        <td className='text-end'>
                                            <a className='me-2' role='button' onClick={() => {
                                                setUpdateModelId(model.id);
                                                setIsModalOpen(true);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
                                                    <g clipPath="url(#clip0_690_2881)">
                                                        <path d="M6 15.5V18H8.5L15.8733 10.6266L13.3733 8.12662L6 15.5ZM17.8067 8.69329C18.0667 8.43329 18.0667 8.01329 17.8067 7.75329L16.2467 6.19329C15.9867 5.93329 15.5667 5.93329 15.3067 6.19329L14.0867 7.41329L16.5867 9.91329L17.8067 8.69329Z" fill="#A3A3A3" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#C4C4C4" />
                                                    <defs>
                                                        <clipPath id="clip0_690_2881">
                                                            <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="header mt-4">
                    <LimitPage limit={limit} setLimit={setLimit} />
                    <Pagination currentPage={currentPage} limit={limit} onChange={(page) => setCurrentPage(page)} maxCount={maxCount} />
                </div>
            </div>
        </>
    );
}

export default Transactions;