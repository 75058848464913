import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import ModalInput from '../ModalInput';
import AsyncCreatablePartner from '../AsyncCreatablePartner';
import ModalSelect from '../ModalSelect';
import { useSelector } from 'react-redux';

export default function ObjectPlatforms({ nestIndex, control, register, errors }) {
    const platforms = useSelector(state => state.platforms);

    const { fields, remove, append } = useFieldArray({
        control,
        name: `objects.${nestIndex}.platforms`
    });

    return (
        <div className='object-platforms'>
            {fields.map((item, index) => {
                let fieldName = `objects[${nestIndex}].platforms[${index}]`;

                return (
                    <div className="row align-items-center" key={index}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor={`${fieldName}.name`}>Платформа</label>
                                <select id={`${fieldName}.name`} {...register(`${fieldName}.name`, { required: true })}>
                                    {platforms.map(platform => ({ label: platform.id, value: platform.id })).map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="form-group">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor={`${fieldName}.source_id`}>ID</label>
                                </div>
                                <input type={"text"} id={`${fieldName}.source_id`} {...register(`${fieldName}.source_id`, { required: true })} />
                            </div>
                        </div>
                        <div className="col-md-1 d-flex">

                            <a role="button" onClick={() => {
                                remove(index);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_115_4868)">
                                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z" fill="#EF225D" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_115_4868">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>

                        </div>
                    </div>
                );
            })}

            <a role="button" className='add' onClick={() => {
                append({ name: "", source_id: "" });
            }}>
                Добавить платформу
            </a>
        </div>

    );
}