import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/auth';
import progressReducer from './reducers/progress';
import platformsReducer from './reducers/platforms';

const store = configureStore({
    reducer: {
        auth: authReducer,
        progress: progressReducer,
        platforms: platformsReducer
    },
});

export default store;