import React from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import ModalInput from '../ModalInput';
import AsyncCreatablePartner from '../AsyncCreatablePartner';

export default function UnsortedCoAuthors({ nestIndex, control, register, errors }) {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `objects.${nestIndex}.coauthors`
    });

    const watch = useWatch({ control, name: "objects" });

    return (
        <>
            {fields.map((item, index) => {
                let fieldName = `objects[${nestIndex}].coauthors[${index}]`;

                return (
                    <div className="row align-items-center" key={watch[nestIndex].coauthors[index].partner_id}>
                        <div className="col-md-7">
                            <div className="form-group">
                                <label htmlFor={`${fieldName}.partner_id`}>Со-автор</label>
                                <Controller
                                    control={control}
                                    rules={{ required: false }}
                                    name={`${fieldName}.partner_id`}
                                    defaultValue={item.partner_id}
                                    render={({ field }) => (
                                        <AsyncCreatablePartner
                                            {...field}
                                            className={"modal-multi-select " + (errors.partner_id?.type)}
                                            placeholder=" - Выберите партнера - "
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 flex-grow-1">
                            <ModalInput name={`${fieldName}.rate]`} label={`Ставка`} register={register} errors={errors} />
                        </div>
                        <div className="col d-flex">
                            <a role="button" onClick={() => {
                                append({ partner_id: "", rate: "" });
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_115_4854)">
                                        <path d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="#4FD28C" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_115_4854">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                            {index != 0 ? <a role="button" className='ms-2' onClick={() => {
                                remove(index);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_115_4868)">
                                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z" fill="#EF225D" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_115_4868">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a> : <a className='fill-24 ms-2'></a>}

                        </div>
                    </div>
                );
            })}
        </>

    );
}