import React, { useEffect, useState } from "react";

import {
    Routes,
    Route,
    BrowserRouter
} from "react-router-dom";

import { Provider, connect } from "react-redux";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { login, logout, mutateUser } from "./redux/reducers/auth";
import { setProgress } from "./redux/reducers/progress";
import { setPlatforms } from "./redux/reducers/platforms";
import store from "./redux/store";
import { getData } from "./utils/storage";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

import Partners from "./pages/Partners";
import Objects from "./pages/Objects";
import PartnerView from "./pages/PartnerView";
import ObjectView from "./pages/ObjectView";
import { get } from "./utils/request";
import Unsorted from "./pages/Unsorted";
import Users from "./pages/Users";
import Transactions from "./pages/Transactions";
import Reports from "./pages/Reports";

registerLocale('ru', ru);

function App({ auth, login, logout, mutateUser, progress, setProgress, setPlatforms }) {

    const [isLoading, setIsLoading] = useState(true);

    const fetchPlatforms = async () => {
        try {
            let res = await get("platforms");

            if (res.models?.length > 0) {
                setPlatforms(res.models);
            }
        } catch (ex) {
            toast.error("Ошибка при получении платформы");
        }
    }

    React.useEffect(() => {
        const fetchUser = async () => {
            try {
                let token = await getData("token");

                axios.interceptors.request.use(config => {
                    setProgress(Math.floor(Math.random() * (50 - 10 + 1) + 10));

                    return config;
                }, error => {
                    setProgress(100);

                    return Promise.reject(error);;
                });

                axios.interceptors.response.use(
                    (response) => {
                        setProgress(100);

                        return response;
                    },
                    async (error) => {
                        setProgress(100);

                        if (error.response)
                            if (error.response.status === 401) {
                                logout();
                            } else {
                                return Promise.reject(error);
                            }
                    },
                );

                if (token) {
                    login(token);

                    await mutateUser();
                }
            } catch (ex) {
                console.log(ex);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, []);

    useEffect(() => {
        if (auth.user) {
            fetchPlatforms();
        }
    }, [auth.user]);

    if (isLoading) {
        return <div style={{ width: "100vw", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-5x text-dark"></i>
        </div>
    }

    return (
        <div className="App">
            <ToastContainer />
            <BrowserRouter>
                {auth.user ? (
                    <>
                        <LoadingBar color="#EF255D" progress={progress} onLoaderFinished={() => setProgress(0)} />

                        <Navbar />
                        <div className="d-flex">
                            <div className="sidebar">
                                <Sidebar />
                            </div>
                            <div className="content">
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/partners" element={<Partners />} />
                                    <Route path="/partner/:id" element={<PartnerView />} />
                                    <Route path="/partner/objects/:partner_id" element={<Objects />} />
                                    <Route path="/partner/transactions/:partner_id" element={<Transactions />} />
                                    <Route path="/objects" element={<Objects />} />
                                    <Route path="/object/:id" element={<ObjectView />} />
                                    <Route path="/unsorted" element={<Unsorted />} />
                                    <Route path="/users" element={<Users />} />
                                    <Route path="/reports" element={<Reports />} />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </div>
                        </div>

                    </>
                ) : (
                    <Routes>
                        <Route path="*" element={<Login />} />
                    </Routes>
                )}
            </BrowserRouter>
        </div>
    );
}

const ConnectedApp = connect(state => {
    return {
        auth: state.auth,
        progress: state.progress
    };
}, { login, logout, mutateUser, setProgress, setPlatforms })(App);

export default () => {
    return (
        <Provider store={store}>
            <ConnectedApp />
        </Provider>
    )
};