import React from 'react';

export default function ModalSection({ title }) {
    return (
        <div className="section">
            <div>
                {title}
            </div>
            <hr />
        </div>
    );
}