import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ModalSection from '../modal/ModalSection';
import Modal from '../modal/Modal';
import ModalInput from './ModalInput';
import ModalSelect from './ModalSelect';
import { post } from '../../utils/request';
import { toast } from 'react-toastify';
import AsyncCreatablePartner from './AsyncCreatablePartner';
import moment from 'moment';

export default function TransactionForm({ setIsOpen, fetchModels, model, partner }) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch
    } = useForm({
        defaultValues: {
            date: model?.date ?? moment().format("YYYY-MM-DD"),
            time: model?.time ?? "11:00",
            route: model?.route ?? "",
            comment: model?.comment ?? "",
            currency: model?.currency ?? "uzs",
            amount: model?.amount ?? "",
            user_id: partner.id,
            type: model?.type ?? 1,
        }
    });

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            let res = await post(model ? `transactions/update?id=${model.id}` : "transactions/create", data);

            if (res.status == "success") {
                setIsOpen(false);
                toast.success(res.message);
                fetchModels && fetchModels();
            } else {
                toast.error(res.message);
            }
        } catch (ex) {
            toast.error("Произошла ошибка при добавлении")
        }

        setIsLoading(false);
    };

    const type = watch("type");

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='py-4'>
            <div className="form-group">
                <label htmlFor={`partner_id`}>Партнер</label>
                <input type="text" value={partner.fullname} readOnly />
            </div>

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="date" type='date' required label={"Дата"} errors={errors} />
                </div>
                <div className="col">
                    <ModalInput register={register} name="time" type='time' required label={"Время"} errors={errors} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="amount" type='number' required label={"Сумма"} errors={errors} />
                </div>
                <div className="col d-flex align-items-center align-content-center flex-wrap">
                    <div class="form-check w-100">
                        <input class="form-check-input" type="radio" id="type1" checked={type == 1} {...register("type")} value={1} />
                        <label class="form-check-label" htmlFor="type1">
                            Вывод
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="type2" checked={type == 2} {...register("type")} value={2} />
                        <label class="form-check-label" htmlFor="type2">
                            Доп. доход
                        </label>
                    </div>
                </div>
            </div>

            <ModalInput register={register} name="route" label={"Способ оплаты"} required errors={errors} />

            <div className="form-group">
                <label htmlFor={"comment"}>Коммент</label>
                <textarea rows={4} className={errors.comment?.type} id={"comment"} {...register("comment", { required: true })} />
            </div>

            <div className="d-flex justify-content-end">
                <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    {isLoading && <i className="fa fa-spinner rotating me-2"></i>}
                    {model ? "Редактировать транзакцию" : "Добавить транзакцию"}
                </button>
            </div>
        </form>
    );
}