import { createSlice } from "@reduxjs/toolkit";

const initialState = 0;

const progressSlice = createSlice({
    name: "progress",
    initialState,
    reducers: {
        setProgress(state, action) {
            state = action.payload;

            return action.payload;
        },

    }
});

export const { setProgress } = progressSlice.actions;

export default progressSlice.reducer;