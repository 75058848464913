import { forwardRef, useEffect, useState } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request.js';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export default forwardRef((props, ref) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(props.isMulti ? [] : undefined);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchValue = async () => {
            try {
                let res = await get(`partners/get?id=${props.value}`);

                if (res.status == "success") {
                    setValue({ label: res.model.fullname, value: res.model.id });
                }
            } catch (ex) {
                console.log(ex);
            }
        }

        if (props.value) {
            fetchValue();
        }
    }, [props.value]);

    const handleCreate = async (inputValue) => {
        setIsLoading(true);

        let res = await post(`partners/creatable`, ({
            fullname: inputValue,
        }));

        if (res?.status === "success") {
            toast.success(res.message);
            let newPerson = { value: res.model.id, label: res.model.fullname };
            setValue(props.isMulti ? [...value, newPerson] : newPerson);
            props.onChange(props.isMulti ? [...value, newPerson.value] : newPerson.value);
        }

        setIsLoading(false);
    }

    const loadPartners = async (inputValue, callback) => {
        cancel && cancel();

        let partners = await get(`partners/light?search=${inputValue}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        let options = partners.models.map(partner => ({ label: partner.fullname, value: partner.id }));

        if (callback)
            callback(options);

        setOptions(options);

        return options;
    }

    const onChange = (newValue, actionMeta) => {
        setValue(props.isMulti ? [...newValue] : { ...newValue });
        props.onChange(newValue.value);

        return newValue;
    };

    return <AsyncCreatable
        {...props}
        ref={ref}
        isLoading={isLoading}
        onCreateOption={handleCreate}
        formatCreateLabel={(inputValue) => `Создать партнера "${inputValue}"`}
        defaultOptions
        onChange={onChange}
        options={options}
        noOptionsMessage={({ inputValue }) => inputValue.length == 0 ? "Введите буквы" : "Пусто"}
        value={value}
        loadOptions={loadPartners}
    />;
});