import React from 'react';
import { Helmet } from 'react-helmet';

export default function NotFound() {
    React.useEffect(() => {

    }, []);

    return <div className="i-body">
        <Helmet>
            <title>{"Not Found (#404)" + " | RizaNova"}</title>
        </Helmet>
        <div className="container login-page v-index">
            <div className="wrapper pt-5">
                <div className="box pb-20">
                    <h1 className="text-danger text-center">Not Found (#404)</h1>

                    <div className="alert alert-default px-0 text-dark text-center">
                        {("Страница не найдена")}.
                    </div>

                </div>
            </div>
        </div>
    </div>
}