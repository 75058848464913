import React, { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { get } from '../utils/request';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import PartnerModal from '../components/modal/PartnerModal';
import moment from 'moment';
import { number_format } from '../utils/functions';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

export default function PartnerView() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate();

    const [model, setModel] = useState();
    const [withdraw, setWithdraw] = useState();
    const [platforms, setPlatforms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [type, setType] = useState("earn");

    const [updateModelId, setUpdateModelId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { id } = useParams();

    const fetchModel = useCallback(async () => {
        setIsLoading(true);

        try {
            let res = await get(`partners/get-all?id=${id}&start=${moment(startDate).format("YYYY-MM")}&end=${moment(endDate).format("YYYY-MM")}`);

            if (res.status == "success") {
                setModel(res.model);
                setPlatforms(res.platforms);

                setWithdraw(res.withdraw.length > 0 ? res.withdraw[0].amount : 0);
            }
        } catch (ex) {
            console.log(ex);
            toast.error("Ошибка при получение данных");
        }

        setIsLoading(false);
    }, [id, startDate, endDate]);

    useEffect(() => {
        if (id && startDate && endDate)
            fetchModel();
    }, [id, startDate, endDate]);

    if (isLoading) {
        return <div style={{ width: "100%", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-4x text-dark"></i>
        </div>
    }

    if (!model) {
        return navigate("/not-found")
    }

    return (
        <div className='partner'>
            <Helmet>
                <title>Партнер</title>
            </Helmet>

            <PartnerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} updateModelId={updateModelId} setUpdateModelId={setUpdateModelId} fetchModels={fetchModel} />

            <div className="header">
                <h1 className='title'>Партнеры - {model.fullname}</h1>

                <div className="actions">
                    <div className="month-range-picker-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_139_8556)">
                                <path d="M16.6667 2.50001H15.8334V0.833344H14.1667V2.50001H5.83335V0.833344H4.16669V2.50001H3.33335C2.41669 2.50001 1.66669 3.25001 1.66669 4.16668V17.5C1.66669 18.4167 2.41669 19.1667 3.33335 19.1667H16.6667C17.5834 19.1667 18.3334 18.4167 18.3334 17.5V4.16668C18.3334 3.25001 17.5834 2.50001 16.6667 2.50001ZM16.6667 17.5H3.33335V6.66668H16.6667V17.5Z" fill="#111111" />
                            </g>
                            <defs>
                                <clipPath id="clip0_139_8556">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <ReactDatePicker
                            className='month-range-picker'
                            selected={startDate}
                            onChange={([newStartDate, newEndDate]) => {
                                setStartDate(newStartDate);
                                setEndDate(newEndDate);
                            }}
                            onCalendarClose={() => {
                                if (startDate && !endDate) {
                                    setEndDate(startDate);
                                }
                            }}
                            selectsRange
                            startDate={startDate}
                            locale="ru"
                            endDate={endDate}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    {/* <a role='button' className="btn btn-default ms-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_139_8559)">
                                <path d="M15.8334 7.5H12.5V2.5H7.50002V7.5H4.16669L10 13.3333L15.8334 7.5ZM4.16669 15V16.6667H15.8334V15H4.16669Z" fill="#111111" />
                            </g>
                            <defs>
                                <clipPath id="clip0_139_8559">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Экспорт
                        </span>
                    </a> */}
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-4">
                    <div className="box">
                        <div className="info d-flex align-items-center">
                            <img src="/images/avatar.jpg" alt="" />
                            <div className="fullname">
                                <h3>{model.billing_fullname ?? model.fullname}</h3>
                                <p>{model.alias}</p>
                            </div>
                        </div>
                        <div className="balance">
                            <div className="title">Доступно</div>
                            <div className="available">{number_format(model.balance_uzs)} сум</div>
                            <div className="title margin">Оплачено</div>
                            <div className="withdraw">{number_format(withdraw)} сум</div>
                        </div>
                        {model.contact_phone && model.contact_email && <div className="contact">
                            {model.contact_phone && <div className="d-flex justify-content-between">
                                <div>Контакты</div>
                                <div>
                                    <div>
                                        {model.contact_phone}
                                    </div>
                                </div>
                            </div>}
                            {model.contact_email && <div className="d-flex justify-content-between margin">
                                <div>Почта:</div>
                                <div>
                                    <div>
                                        {model.contact_email}
                                    </div>
                                </div>
                            </div>}
                        </div>}

                        <div className="actions">
                            <a role="button" className='edit' onClick={() => {
                                setUpdateModelId(model.id);
                                setIsModalOpen(true);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <g clipPath="url(#clip0_97_3972)">
                                        <path d="M3 14.375V17.5H6.125L15.3417 8.28334L12.2167 5.15834L3 14.375ZM17.7583 5.86667C18.0833 5.54167 18.0833 5.01667 17.7583 4.69167L15.8083 2.74167C15.4833 2.41667 14.9583 2.41667 14.6333 2.74167L13.1083 4.26667L16.2333 7.39167L17.7583 5.86667V5.86667Z" fill="#F1A239" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_97_3972">
                                            <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Редактировать профиль
                            </a>
                            {/* <a role="button" className='button mt-2'>
                                    Все данные
                                </a> */}
                        </div>
                    </div>

                    <div className="box mt-3 objects">
                        <div className="title fs-20">Популярные объекты</div>

                        <table className='home-table mt-3 no-diff'>
                            <tbody>
                                {model.user_objects.length == 0 && <tr><td colSpan={12}>Ничего не найдено</td></tr>}

                                {model.user_objects.map(user_object => {
                                    return (
                                        <tr className='cursor-pointer' key={user_object.object.id} onClick={() => {
                                            navigate(`/object/${user_object.object.id}`);
                                        }}>
                                            <td>
                                                <div className="name">{user_object.object.name}</div>
                                            </td>
                                            {/* <td className='end'>
                                                890 196
                                            </td> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <div className="margin"></div>

                        {model.user_objects.length > 0 && <NavLink to={`/partner/objects/${model.id}`} className={"button"}>
                            Все объекты
                        </NavLink>}
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="box earn-chart">
                        <div className="nav text-center">
                            <a role="button" className={type == "earn" ? 'active' : ''} onClick={() => {
                                setType("earn");
                            }}>
                                <div className='text'>Общий доход (сум)</div>
                                <div className='earn'>{platforms.length == 0 ? 0 : number_format(platforms.map(platform => platform._sum.earn).reduce((a, b) => parseFloat(a) + parseFloat(b), 0))}</div>
                                {/* <div className='difference green'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <g clipPath="url(#clip0_97_3982)">
                                            <path d="M7.5 14L12.5 9L17.5 14H7.5Z" fill="#4FD28C" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_97_3982">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>
                                        240 000
                                    </span>
                                </div> */}
                            </a>
                            <a role="button" className={type == "views" ? 'active' : ''} onClick={() => {
                                setType("views");
                            }}>
                                <div className='text'>Всего просмотров</div>
                                <div className='earn'>{platforms.length == 0 ? 0 : number_format(platforms.map(platform => platform._sum.views).reduce((a, b) => parseFloat(a) + parseFloat(b), 0))}</div>
                                {/* <div className='difference red'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <g clipPath="url(#clip0_97_3988)">
                                            <path d="M17.5 10L12.5 15L7.5 10L17.5 10Z" fill="#EF225D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_97_3988">
                                                <rect width="24" height="24" fill="white" transform="translate(24.5 24) rotate(-180)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>
                                        7 112 000
                                    </span>
                                </div> */}
                            </a>
                        </div>

                        <div className="chart">
                            <Bar options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    datalabels: {
                                        display: false
                                    }
                                },
                                scales: {
                                    y: {
                                        grid: {
                                            display: true
                                        },
                                        max: Math.max(...platforms.map(platform => platform._sum[type])) * 1.2,
                                        ticks: {
                                            font: {
                                                size: 12
                                            },
                                            color: "#A3A3A3"
                                        }
                                    },
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            font: {
                                                size: 14,
                                            },
                                            color: "#111111"
                                        }
                                    }
                                },
                            }
                            } data={{
                                labels: platforms.map(platform => platform.platform_id),
                                datasets: [
                                    {
                                        label: type == "earn" ? 'Доход' : "Просмотры",
                                        data: platforms.map(platform => platform._sum[type]),
                                        maxBarThickness: Math.max(...platforms.map(platform => platform._sum[type])) * 1.2,
                                        backgroundColor: ['#F1A239', "#EF225D", "#193F57", "#49A2C4", "#F5C36D", "#3C86A3", "#B5B5B5", "#142E66", "#CC4264", '#F1A239', "#EF225D", "#193F57", "#49A2C4", "#F5C36D", "#3C86A3", "#B5B5B5", "#142E66", "#CC4264"]
                                    },
                                ],
                            }} />
                        </div>
                    </div>

                    <div className="box mt-4">
                        <div className="title">
                            <NavLink to={`/partner/transactions/${model.id}`} className={"link"}>
                                <span className='me-2'>
                                    Транзакции
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_97_4047)">
                                        <path d="M9 5V7H15.59L4 18.59L5.41 20L17 8.41V15H19V5H9Z" fill="#C4C4C4" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_97_4047">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </NavLink>
                        </div>

                        <div className="main-table no-shadow p-0 mt-4">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <a role="button">Дата</a>
                                        </th>
                                        <th>
                                            <a role="button">Сумма</a>
                                        </th>
                                        <th>
                                            <a role="button">Источник</a>
                                        </th>
                                        <th>
                                            <a role="button">Комментария</a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {model.transactions.length == 0 && <tr><td colSpan={12}>Ничего не найдено</td></tr>}
                                    {model.transactions.map(transaction => {
                                        return (
                                            <tr key={transaction.id}>
                                                <td>
                                                    {moment(model.date).format("DD.MM.YYYY HH:mm")}
                                                </td>
                                                <td className={transaction.type == 1 ? 'green' : 'red'}>
                                                    {transaction.type == 1 ? '+' : '-'} {transaction.amount} сум
                                                </td>
                                                <td>
                                                    {transaction.route}
                                                </td>
                                                <td>
                                                    {transaction.comment}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}