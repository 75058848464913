import moment from "moment";
import React from "react";

export function useIsMounted() {
    const ref = React.useRef(false);

    React.useEffect(() => {
        ref.current = true;

        return () => {
            ref.current = false;
        };
    }, []);

    return React.useCallback(() => ref.current, [ref]);
}

export function formatDate(date) {
    if (!date) return "";

    return moment(date).format("DD.MM.YYYY HH:mm");
}

export function platformToImages(platforms, platformText) {
    let images = [];
    let text = [];

    if (!platformText) return "";

    let data = platformText.split(", ");

    for (const platformId of data) {
        let platform = platforms.find(p => p.id == platformId);

        if (platform && platform.image) {
            images.push(<img key={platformId} src={platform.image} style={{ height: 20, marginRight: 5 }} />);
        } else {
            text.push(platformId);
        }
    }

    return [...images, text.join(", ")];
}

export function number_format(number, decimals = 0, dec_point = ".", thousands_sep = " ") {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const arrayToFormData = (data) => {
    let formData = new FormData;

    for (let prop in data) {

        if (Array.isArray(data[prop])) {
            for (let index in data[prop]) {
                if (!(data[prop][index] instanceof File) && typeof data[prop][index] == "object") {
                    for (let p in data[prop][index]) {
                        formData.append(prop + "[" + index + "][" + p + "]", data[prop][index][p]);
                    }
                } else
                    formData.append(prop + "[]", data[prop][index]);
            }
        } else {
            formData.append(prop, data[prop]);
        }
    }

    return formData;

};

export function reportStatusName(status) {
    if (status == 1) {
        return "Ожидание очереди";
    } else if (status == 2) {
        return "Идет процесс";
    } else if (status == 3) {
        return "Ошибка";
    } else if (status == 4) {
        return "Успешно";
    } else {
        return "Неизвестный статус";
    }
}