import React from 'react';

export default function SortableTableHeader({ title, value, order, setOrder, orderType, setOrderType, defaultSortType = "asc", position = "start" }) {

    return (
        <a role="button" className={`d-flex align-items-center justify-content-${position}`} onClick={() => {
            if (order != value) {
                setOrder(value);
                setOrderType(defaultSortType);
            } else {
                setOrderType(orderType == "asc" ? "desc" : "asc");
            }
        }}>
            {title}
            {order == value && (
                orderType == "asc" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_71_1169)">
                            <path d="M11.06 5.72668L8 8.78002L4.94 5.72668L4 6.66668L8 10.6667L12 6.66668L11.06 5.72668Z" fill="#A3A3A3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_71_1169">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_71_1169)">
                            <path d="M4.94 10.2733L8 7.21998L11.06 10.2733L12 9.33332L8 5.33332L4 9.33331L4.94 10.2733Z" fill="#A3A3A3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_71_1169">
                                <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
                            </clipPath>
                        </defs>
                    </svg >
                )
            )}
        </a >
    );
}