import React from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import ModalInput from '../ModalInput';
import AsyncCreatablePartner from '../AsyncCreatablePartner';
import ObjectPlatforms from './ObjectPlatforms';
import Select from 'react-select';
import ObjectCoAuthours from './ObjectCoAuthors';
import { useSelector } from 'react-redux';

export default function ObjectFields({ control, register, setValue, getValues, errors }) {
    const platforms = useSelector(state => state.platforms);

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "objects"
    });

    const watch = useWatch({ control, name: "objects" });

    console.log(watch);

    return (
        <>
            {
                fields.map((item, index) => {
                    const fieldName = `objects[${index}]`;

                    return (
                        <fieldset className={fields.length > 1 ? 'object-item' : ''} name={fieldName} key={fieldName}>
                            <ModalInput register={register} name={`${fieldName}.name`} label={"Название"} defaultValue={item.name} required errors={errors} />

                            {/* <div className="form-group">
                                <label htmlFor={`${fieldName}.platforms`}>Платформы</label>
                                <Controller
                                    name={`${fieldName}.platforms`}
                                    id={`${fieldName}.platforms`}
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                // defaultValue={[{ label: "RizaNova", value: "RizaNova" }]}
                                                onChange={(val) => {
                                                    field.onChange(val.map(v => {
                                                        let current = watch[index].platforms.find(p => p.name == v.value);

                                                        return {
                                                            name: v.value,
                                                            source_id: current ? current.source_id : ""
                                                        };
                                                    }));
                                                }}
                                                value={platforms.map(platform => ({ label: platform.id, value: platform.id })).filter(p => field.value.map(v => v.name).indexOf(p.value) > -1)}
                                                isMulti
                                                isClearable={false}
                                                options={platforms.map(platform => ({ label: platform.id, value: platform.id }))}
                                                className="modal-multi-select mb-2"
                                                classNamePrefix="select"
                                                placeholder=" - Выберите - "
                                            />
                                        );
                                    }}
                                    rules={{ required: false }}
                                />
                            </div> */}

                            <ObjectPlatforms nestIndex={index} control={control} register={register} />

                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor={`${fieldName}.partner_id`}>Автор</label>
                                            {fields.length > 1 && <a role="button" className='for-all' onClick={() => {
                                                for (let i = 0; i < watch.length; i++) {
                                                    setValue(`objects[${i}].partner_id`, watch[index].partner_id);
                                                }
                                            }}>Для всех</a>}
                                        </div>
                                        <Controller
                                            control={control}
                                            rules={{ required: true }}
                                            name={`${fieldName}.partner_id`}
                                            render={({ field }) => (
                                                <AsyncCreatablePartner
                                                    {...field}
                                                    className={"modal-multi-select " + (errors.partner_id?.type)}
                                                    placeholder=" - Выберите партнера - "
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 flex-grow-1">
                                    <ModalInput name={`${fieldName}.rate]`} label={`Ставка`} register={register} required errors={errors}
                                        forAll={fields.length > 1 && <a role="button" className='for-all' onClick={() => {
                                            for (let i = 0; i < watch.length; i++) {
                                                setValue(`objects[${i}].rate`, watch[index].rate);
                                            }
                                        }}>Для всех</a>} />
                                </div>
                                <div className="col d-flex">
                                    <a className='fill-24'></a>
                                    <a className='fill-24 ms-2'></a>
                                </div>
                            </div>

                            <ObjectCoAuthours register={register} nestIndex={index} control={control} errors={errors} />
                        </fieldset>
                    )
                })
            }
        </>
    );
}