import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { deleteRequest, get } from '../utils/request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ObjectModal from '../components/modal/ObjectModal';
import UserModal from '../components/modal/UserModal';
import { useSelector } from 'react-redux';

const CancelToken = axios.CancelToken;
let cancel;

function Users({ }) {
    const auth = useSelector(state => state.auth);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [order, setOrder] = useState("fullname");
    const [orderType, setOrderType] = useState("asc");
    const [models, setModels] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [updateModelId, setUpdateModelId] = useState(null);

    const navigate = useNavigate();

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`admins?order=${order}&orderType=${orderType}&search=${search}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (res.models) {
            setModels(res.models);
        }

        setIsLoading(false);
    }

    const deleteUser = async (model) => {
        if (model.id == auth.user?.id) {
            toast.error("Нельзя удалить свой аккаунт");
            return;
        }

        if (auth.user.role == "editor") {
            toast.error("Доступ запрещен");
            return;
        }

        if (model) {
            if (window.confirm(`Удалить пользователя "${model.fullname}"?`)) {
                let res = await deleteRequest(`admins/delete`, {
                    data: {
                        ids: [model.id]
                    }
                });

                if (res.status == "success") {
                    toast.success(`${res.deleted.count} пользователи удалены`);
                }

                fetchModels();
            }
        } else {
            toast.error("Выберите пользователя");
        }
    };

    useEffect(() => {
        fetchModels();
    }, [order, orderType, search]);

    if (isLoading) {
        return <div style={{ width: "100%", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-4x text-dark"></i>
        </div>
    }


    return (
        <>
            <Helmet>
                <title>Пользователи</title>
            </Helmet>

            <UserModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} fetchModels={fetchModels} updateModelId={updateModelId} setUpdateModelId={setUpdateModelId} />

            <div>
                <div className="header">
                    <h1 className='title'>Пользователи</h1>

                    <div className="actions">
                        <a role='button' className="btn btn-primary ms-3" onClick={() => {
                            setIsModalOpen(true);
                        }}>
                            Добавить
                        </a>
                    </div>
                </div>

                <div className="users">
                    {models.map(model => {
                        return (
                            <div className="user" key={model.id}>
                                <div className="avatar">
                                    <img src={model.image ?? "/images/avatar.jpg"} alt={model.fullname} />
                                </div>
                                <div className="info">
                                    <p className='fullname'>{model.fullname}</p>
                                    <p className='role'>{model.role}</p>
                                </div>

                                {(auth.user.role != "editor" || auth.user.id == model.id) && <div className="actions">
                                    <a role="button" onClick={() => {
                                        setUpdateModelId(model.id);
                                        setIsModalOpen(true);
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g id="edit" clipPath="url(#clip0_86_3119)">
                                                <path id="Vector" d="M3 17.2496V20.9996H6.75L17.81 9.93957L14.06 6.18957L3 17.2496ZM20.71 7.03957C21.1 6.64957 21.1 6.01957 20.71 5.62957L18.37 3.28957C17.98 2.89957 17.35 2.89957 16.96 3.28957L15.13 5.11957L18.88 8.86957L20.71 7.03957Z" fill="#F1A239" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_86_3119">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                    {auth.user.id != model.id && <a role="button" onClick={() => deleteUser(model)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <g id="delete" clipPath="url(#clip0_86_3117)">
                                                <path id="Vector" d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#EF225D" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_86_3117">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>}
                                </div>}
                            </div>
                        )
                    })}
                </div>

            </div>
        </>
    );
}

export default Users;