import React from 'react';

export default function LimitPage({ limit, setLimit }) {
    return (
        <div className="limit">
            <span>
                Список
            </span>
            <select value={limit} onChange={(e) => {
                setLimit(parseInt(e.target.value));
            }}>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
            </select>
        </div>
    );
}