import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import ModalSection from '../modal/ModalSection';
import Modal from '../modal/Modal';
import ModalInput from './ModalInput';
import ModalSelect from './ModalSelect';
import { post } from '../../utils/request';
import { toast } from 'react-toastify';

export default function PartnerForm({ setIsOpen, fetchModels, model }) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: {
            billing_fullname: model?.billing_fullname ?? "",
            fullname: model?.fullname ?? "",
            username: model?.username ?? "",
            password: "",
            confirm_password: "",
            status: model?.status ?? 10,
            type: model?.type ?? 1,
            alias: model?.alias ?? "",
            userside_fullname: model?.userside_fullname ?? "",
            contact_phone: model?.contact_phone ?? "",
            contact_email: model?.contact_email ?? "",
            contract_number: model?.contract_number ?? "",
            contract_date: model?.contract_date ?? "",
            billing_inps: model?.billing_inps ?? "",
            billing_card: model?.billing_card ?? "",
            billing_bank_account: model?.billing_bank_account ?? "",
            billing_bank_mfo: model?.billing_bank_mfo ?? "",
            pasport_serial: model?.pasport_serial ?? "",
            pasport_date: model?.pasport_date ?? "",
            pasport_issued_by: model?.pasport_issued_by ?? "",
        }
    });

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            let res = await post(model ? `partners/update?id=${model.id}` : "partners/create", data);

            if (res.status == "success") {
                setIsOpen(false);
                toast.success(res.message);
                fetchModels && fetchModels();
            } else {
                toast.error(res.message);
            }
        } catch (ex) {
            toast.error("Произошла ошибка при добавлении")
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalSection title="Биллинг" />
            <div className="row">
                <div className="col-7">
                    <ModalInput register={register} name="billing_fullname" label={"Ф.И.О."} required errors={errors} />
                </div>
                <div className="col-5">
                    <ModalSelect register={register} name="type" label={"Тип"} options={[{ value: "1", label: "Артист" }, { value: "2", label: "Продюсер, режиссер" }, { value: "3", label: "Юридическое лицо" }]} required errors={errors} />
                </div>
            </div>

            <ModalSection title="Партнер" />

            <ModalInput register={register} name="fullname" label={"Имя в системе"} required errors={errors} />

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="userside_fullname" label={"На стороне партнера"} errors={errors} />
                </div>
                <div className="col">
                    <ModalInput register={register} name="alias" label={"Твоческий псевдоним"} errors={errors} />
                </div>
            </div>

            <ModalSection title="Паспорт" />

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="alias" label={"Серийный номер"} errors={errors} />

                </div>
                <div className="col">
                    <ModalInput register={register} name="pasport_date" type='date' label={"Дата выдачи"} errors={errors} />
                </div>
            </div>

            <ModalInput register={register} name="pasport_issued_by" label={"Кем выдан"} errors={errors} />

            <ModalSection title="Договор" />

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="contract_number" label={"Номер договора"} errors={errors} />
                </div>
                <div className="col">
                    <ModalInput register={register} name="contract_date" type='date' label={"Дата договора"} errors={errors} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="billing_inps" label={"ИНПС"} errors={errors} />
                </div>
                <div className="col">
                    <ModalInput register={register} name="billing_bank_mfo" label={"МФО банка"} errors={errors} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="billing_card" label={"Номер карты"} errors={errors} />
                </div>
                <div className="col">
                    <ModalInput register={register} name="billing_bank_account" label={"Транзитный счет"} errors={errors} />
                </div>
            </div>

            <ModalSection title="Контакты" />

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="contact_phone" label={"Номер телефона"} errors={errors} />
                </div>
                <div className="col">
                    <ModalInput register={register} name="contact_email" label={"Почта"} errors={errors} />
                </div>
            </div>

            <ModalSection title="Личный кабинет" />

            <ModalInput register={register} name="username" required label={"Логин"} errors={errors} />

            <div className="row">
                <div className="col">
                    <ModalInput register={register} name="password" required={!model} label={"Пароль"} errors={errors} />
                    <div className="text-end" style={{ marginTop: -16 }}>
                        <a role="button" onClick={() => {
                            let generated_password = (Math.random() + 1).toString(36).substring(4);

                            setValue("password", generated_password);
                            setValue("confirm_password", generated_password);
                        }}>генерировать</a>
                    </div>
                </div>
                <div className="col">
                    <ModalInput register={register} name="confirm_password" required={!model} label={"Подтвердите пароль"} errors={errors} />
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    {isLoading && <i className="fa fa-spinner rotating me-2"></i>}
                    {"Сохранить"}
                </button>
            </div>
        </form>
    );
}