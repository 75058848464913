import React, { useEffect, useState } from 'react';

import Modal from '../modal/Modal';
import PartnerForm from '../forms/PartnerForm';
import { get, post } from '../../utils/request';
import { toast } from 'react-toastify';
import ObjectsForm from '../forms/ObjectsForm';

export default function ObjectModal({ isOpen, setIsOpen, fetchModels, updateModelIds = [], setUpdateModelIds }) {
    const [isLoading, setIsLoading] = useState(true);

    const [models, setModels] = useState([]);

    const fetchUpdateModels = async () => {
        try {
            let res = await post(`objects/get-models`, { ids: updateModelIds });

            if (res.status == "success") {
                setModels(res.models);
                setIsLoading(false);
            } else {
                setIsOpen(false);
                toast.error(res.message);
            }
        } catch (ex) {
            setIsOpen(false);
            toast.error("Ошибка при получение данных объектов");
        }
    }

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);

            if (updateModelIds?.length == 0) {
                setIsLoading(false);
            } else {
                fetchUpdateModels();
            }
        } else {
            setUpdateModelIds && setUpdateModelIds([]);
            setModels([]);
        }
    }, [isOpen]);

    if (!isOpen) return;

    return (
        <Modal className='object-modal' isOpen={isOpen} setIsOpen={setIsOpen} label={updateModelIds?.length > 0 ? "Редактирование" : "Добавить объекта"}>
            {isLoading ? (
                <div className='text-center pt-3'>
                    <i className="fa fa-3x fa-spinner rotating"></i>
                </div>
            ) : (
                <ObjectsForm key={isOpen} isOpen={isOpen} setIsOpen={setIsOpen} models={models} fetchModels={fetchModels} />
            )}
        </Modal>
    );
}