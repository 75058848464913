import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";

import { get } from "../../utils/request";
import { removeData, storeData } from "../../utils/storage";

const initialState = {
    user: null
};

export const mutateUser = createAsyncThunk(
    "user/mutate",
    async (payload, { dispatch }) => {
        let res = await get("account/self");

        if (res && res.status == "success") {
            dispatch(login(res.token));
        }

        return res.token;
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action) {
            if (action.payload) {
                try {
                    let user = decodeToken(action.payload);
                    if (user) {
                        state.user = user;
                        storeData("token", action.payload);
                        axios.defaults.headers.common["Authorization"] = "Bearer " + action.payload;
                    }
                } catch (ex) {

                }
            }
        },
        logout(state, action) {
            state.user = null;

            removeData("token");
        }
    },
    // [mutateUser.fulfilled.type]: (state, action) => {
    
    // }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;