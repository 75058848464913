import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import { post } from '../utils/request';
import { connect } from 'react-redux';
import { login } from '../redux/reducers/auth';
import { useIsMounted } from '../utils/functions';

function Login({ auth, login }) {
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const submitHandler = async (data) => {
        setIsLoading(true);

        try {
            let res = await post('auth/login', {
                username: data.username,
                password: data.password,
            });

            console.log(res);

            if (res?.status === 'error') {
                if (isMounted) {
                    setError("username", { type: 'required', message: res.message })
                }
            } else {
                if (isMounted) {
                    login(res.token);
                }
            }

        } catch (ex) {

        }

        setIsLoading(false);
    };

    return <div className="container-fluid">
        <Helmet>
            <title>{"Авторизация"}</title>
        </Helmet>

        <div className="row">
            <div className="col-md-4 mh-100 side-bg">
                <img src="/images/login-bg.jpg" alt="" />
            </div>
            <div className="col-md-8 mh-100">
                <div className="rizanova-login d-flex justify-content-center align-items-center">
                    <div className="">
                        <div className="login">
                            <h3>{("Войти")}</h3>
                            <form onSubmit={handleSubmit(submitHandler)}>
                                <div className="form-group">
                                    <label htmlFor="username">Логин</label>
                                    <div>
                                        <input id='username' type="text" {...register("username", { required: true })} className={"form-control " + (errors.username ? 'has-error' : '')} placeholder={("Введите логин")} />

                                        {errors.username && errors.username.message.length > 0 && <p className="error-block">{errors.username.message}</p>}
                                    </div>
                                </div>

                                <div className="form-group mb-1">
                                    <div className="mt-2">
                                        <label htmlFor="username">Пароль</label>
                                        <div className="position-relative">
                                            <input type={showPassword ? 'text' : "password"} {...register("password", { required: true })} className={"form-control " + (errors.password ? 'has-error' : '')} placeholder={("Введите пароль")} />
                                            <a role="button" className={"eye " + (showPassword ? 'active' : '')} onClick={() => setShowPassword(!showPassword)}>
                                                {/* Eye svg */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 6C7.90909 6 4.41545 8.488 3 12C4.41545 15.512 7.90909 18 12 18C16.0909 18 19.5845 15.512 21 12C19.5845 8.488 16.0909 6 12 6ZM12 16C9.74182 16 7.90909 14.208 7.90909 12C7.90909 9.792 9.74182 8 12 8C14.2582 8 16.0909 9.792 16.0909 12C16.0909 14.208 14.2582 16 12 16ZM12 9.6C10.6418 9.6 9.54545 10.672 9.54545 12C9.54545 13.328 10.6418 14.4 12 14.4C13.3582 14.4 14.4545 13.328 14.4545 12C14.4545 10.672 13.3582 9.6 12 9.6Z" fill="#898989" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <button type="submit" className="submit btn btn-warning text-white w-100 mt-3" disabled={isLoading}>{
                                        isLoading && <i className="fa fa-spinner rotating"></i>
                                    } {("Войти")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
}

export default connect(state => ({
    auth: state.auth
}), { login })(Login);