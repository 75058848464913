import React from 'react';

import Modal from './Modal';
import ReportForm from '../forms/ReportReport';

export default function ReportModal({ isOpen, setIsOpen, fetchModels }) {
    if (!isOpen) return;

    return (
        <Modal isOpen={isOpen} className='report-popup' setIsOpen={setIsOpen} closeOnClickOutside={false} label={"Загрузка отчета"}>
            <ReportForm key={isOpen} isOpen={isOpen} setIsOpen={setIsOpen} fetchModels={fetchModels} />
        </Modal>
    );
}