import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import moment from 'moment/moment';

import Checkbox from '../components/forms/Checkbox';
import Pagination from '../components/Pagination';
import { deleteRequest, get } from '../utils/request';
import LimitPage from '../components/forms/LimitPage';
import SortableTableHeader from '../components/table/SortableTableHeader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import UnsortedModal from '../components/modal/UnsortedModal';

const CancelToken = axios.CancelToken;
let cancel;

function Unsorted() {
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [maxCount, setMaxCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [order, setOrder] = useState("name");
    const [orderType, setOrderType] = useState("asc");
    const [models, setModels] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ids, setIds] = useState([]);

    const fetchModels = async () => {
        cancel && cancel();

        setIsLoading(true);

        let res = await get(`unsorted?limit=${limit}&page=${currentPage}&order=${order}&orderType=${orderType}&search=${search}`, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        });

        if (res.models) {
            setIds([]);
            setModels(res.models);
            setMaxCount(Number.parseInt(res.maxCount));
        }

        setIsLoading(false);
    }

    const handleDelete = useCallback(async () => {
        if (ids.length > 0) {
            if (window.confirm("Удалить выбранных объектов ?")) {
                let res = await deleteRequest(`unsorted/delete`, {
                    data: {
                        ids
                    }
                });

                if (res.status == "success") {
                    toast.success(`${res.deleted.count} объектов удалены`);
                }

                fetchModels();
            }
        } else {
            toast.error("Выберите объектов");
        }
    }, [ids]);

    useEffect(() => {
        fetchModels();
    }, [limit, currentPage, order, orderType, search]);

    return (
        <>
            <Helmet>
                <title>Несортированные</title>
            </Helmet>

            <UnsortedModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} updateModelIds={ids} setUpdateModelIds={setIds} fetchModels={fetchModels} />

            <div>
                <div className="header">
                    <h1 className='title'>Несортированные</h1>
                </div>

                <div className="header mt-4">
                    <div className='d-flex'>
                        <LimitPage limit={limit} setLimit={setLimit} />
                        
                        <label className="search d-flex align-items-center ps-2 ms-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5763 10.7406H10.0343L9.8422 10.5322C10.5146 9.65223 10.9194 8.50986 10.9194 7.26715C10.9194 4.49614 8.92281 2.25 6.45969 2.25C3.99657 2.25 2 4.49614 2 7.26715C2 10.0382 3.99657 12.2843 6.45969 12.2843C7.56432 12.2843 8.57976 11.8289 9.36192 11.0725L9.54717 11.2886V11.8984L12.9777 15.75L14 14.5999L10.5763 10.7406ZM6.4597 10.7406C4.75129 10.7406 3.37222 9.18911 3.37222 7.26716C3.37222 5.3452 4.75129 3.79374 6.4597 3.79374C8.1681 3.79374 9.54717 5.3452 9.54717 7.26716C9.54717 9.18911 8.1681 10.7406 6.4597 10.7406Z" fill="#A3A3A3" />
                            </svg>
                            <input type="text" className="form-control" placeholder='Поиск' value={search} onChange={(e) => setSearch(e.target.value)} />
                        </label>
                    </div>

                    <div className="actions">


                        {/* <a role='button' className="btn btn-default ms-3">Категории</a> */}
                        <a role='button' className="btn btn-default ms-3" onClick={() => {
                            handleDelete();
                        }}>Удалить</a>
                        <a role='button' className="btn btn-primary ms-3" onClick={() => {
                            if (ids.length == 0) {
                                toast.warning("Выберите объектов");
                            } else {
                                setIsModalOpen(true);
                            }
                        }}>
                            Редактировать объектов
                        </a>
                    </div>
                </div>

                <div className="main-table">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>
                                    <Checkbox checked={models.length > 0 && ids.length == models.length} onChange={() => {
                                        if (ids.length != models.length) {
                                            setIds(models.map((model) => model.id));
                                        } else {
                                            setIds([]);
                                        }
                                    }} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"ID"} value={"id"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Название"} value={"name"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Просмотры"} value={"views"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Доход"} value={"earn"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"Площадка"} value={"platform_id"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                                <th>
                                    <SortableTableHeader title={"ID источника"} value={"source_id"} order={order} setOrder={setOrder} orderType={orderType} setOrderType={setOrderType} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.length == 0 && <tr><td className='text-center' colSpan={15}>{!isLoading ? "Ничего не найдено" : "Идет загрузка..."}</td></tr>}
                            {models.map((model, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Checkbox checked={!!ids.find(id => id == model.id)} onClick={(e) => {
                                                let lastIndex = models.findIndex((model) => model.id == ids[ids.length - 1]);

                                                if (
                                                    e.target.checked
                                                ) {
                                                    if (e.shiftKey && lastIndex > -1) {
                                                        setIds([
                                                            ...ids,
                                                            ...models.filter((model, ii) => lastIndex < index ? ii >= lastIndex && ii <= index : ii >= index && ii <= lastIndex).map((model) => model.id)
                                                        ]);
                                                    } else {
                                                        setIds([
                                                            ...ids,
                                                            model.id,
                                                        ]);
                                                    }
                                                } else {
                                                    setIds(ids.filter(id => id != model.id));
                                                }
                                            }} />
                                        </td>
                                        <td>
                                            {model.id}
                                        </td>
                                        <td>
                                            {model.name}
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>{model.platform?.image && <img src={model.platform?.image} style={{ height: 20 }} />}</td>
                                        <td>{model.source_id}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="header mt-4">
                    <LimitPage limit={limit} setLimit={setLimit} />
                    <Pagination currentPage={currentPage} limit={limit} onChange={(page) => setCurrentPage(page)} maxCount={maxCount} />
                </div>
            </div>
        </>
    );
}

export default connect(state => ({ platforms: state.platforms }))(Unsorted);