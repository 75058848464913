import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { logout } from '../redux/reducers/auth';
import SidebarItem from './items/SidebarItem';

function Sidebar({ logout }) {

    return (
        <ul>
            <li>
                <NavLink to={"/"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                            <path d="M3.75903 13H11.9839V3H3.75903V13ZM3.75903 21H11.9839V15H3.75903V21ZM14.0402 21H22.2651V11H14.0402V21ZM14.0402 3V9H22.2651V3H14.0402Z" fill="#a3a3a3" />
                        </svg>
                        <span>
                            Обзор
                        </span>
                    </div>
                </NavLink>
            </li>
            <li>
                <NavLink to={"/partners"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_126_10939)">
                                <path d="M12 12.75C13.63 12.75 15.07 13.14 16.24 13.65C17.32 14.13 18 15.21 18 16.38V18H6V16.39C6 15.21 6.68 14.13 7.76 13.66C8.93 13.14 10.37 12.75 12 12.75ZM4 13C5.1 13 6 12.1 6 11C6 9.9 5.1 9 4 9C2.9 9 2 9.9 2 11C2 12.1 2.9 13 4 13ZM5.13 14.1C4.76 14.04 4.39 14 4 14C3.01 14 2.07 14.21 1.22 14.58C0.48 14.9 0 15.62 0 16.43V18H4.5V16.39C4.5 15.56 4.73 14.78 5.13 14.1ZM20 13C21.1 13 22 12.1 22 11C22 9.9 21.1 9 20 9C18.9 9 18 9.9 18 11C18 12.1 18.9 13 20 13ZM24 16.43C24 15.62 23.52 14.9 22.78 14.58C21.93 14.21 20.99 14 20 14C19.61 14 19.24 14.04 18.87 14.1C19.27 14.78 19.5 15.56 19.5 16.39V18H24V16.43ZM12 6C13.66 6 15 7.34 15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_126_10939">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Партнеры
                        </span>
                    </div>
                </NavLink>
            </li>
            <li>
                <NavLink to={"/objects"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_71_648)">
                                <path d="M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM7 19H21V17H7V19ZM7 13H21V11H7V13ZM7 5V7H21V5H7Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_71_648">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Объекты
                        </span>
                    </div>
                </NavLink>
            </li>
            <li>
                <NavLink to={"/unsorted"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_662_8595)">
                                <path d="M8 8H6V15C6 16.1 6.9 17 8 17H17V15H8V8Z" fill="#A3A3A3" />
                                <path d="M20 3H12C10.9 3 10 3.9 10 5V11C10 12.1 10.9 13 12 13H20C21.1 13 22 12.1 22 11V5C22 3.9 21.1 3 20 3ZM20 11H12V7H20V11Z" fill="#A3A3A3" />
                                <path d="M4 12H2V19C2 20.1 2.9 21 4 21H13V19H4V12Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_662_8595">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Несортированные
                        </span>
                    </div>
                </NavLink>
            </li>
            <li>
                <NavLink to={"/reports"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_71_641)">
                                <path d="M20.41 8.41L15.58 3.58C15.21 3.21 14.7 3 14.17 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V9.83C21 9.3 20.79 8.79 20.41 8.41ZM7 7H14V9H7V7ZM17 17H7V15H17V17ZM17 13H7V11H17V13Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_71_641">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Отчеты
                        </span>
                    </div>
                </NavLink>
            </li>
            {/* <li>
                <NavLink to={"/requests"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_85_5351)">
                                <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_85_5351">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Запросы
                        </span>
                    </div>
                </NavLink>
            </li> */}
            <li>
                <NavLink to={"/users"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_126_10951)">
                                <path d="M16.5 12C17.88 12 18.99 10.88 18.99 9.5C18.99 8.12 17.88 7 16.5 7C15.12 7 14 8.12 14 9.5C14 10.88 15.12 12 16.5 12ZM9 11C10.66 11 11.99 9.66 11.99 8C11.99 6.34 10.66 5 9 5C7.34 5 6 6.34 6 8C6 9.66 7.34 11 9 11ZM16.5 14C14.67 14 11 14.92 11 16.75V19H22V16.75C22 14.92 18.33 14 16.5 14ZM9 13C6.67 13 2 14.17 2 16.5V19H9V16.75C9 15.9 9.33 14.41 11.37 13.28C10.5 13.1 9.66 13 9 13Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_126_10951">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Пользователи
                        </span>
                    </div>
                </NavLink>
            </li>
            {/* <li>
                <NavLink to={"/settings"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_71_632)">
                                <path d="M19.1401 12.94C19.1801 12.64 19.2001 12.33 19.2001 12C19.2001 11.68 19.1801 11.36 19.1301 11.06L21.1601 9.47999C21.3401 9.33999 21.3901 9.06999 21.2801 8.86999L19.3601 5.54999C19.2401 5.32999 18.9901 5.25999 18.7701 5.32999L16.3801 6.28999C15.8801 5.90999 15.3501 5.58999 14.7601 5.34999L14.4001 2.80999C14.3601 2.56999 14.1601 2.39999 13.9201 2.39999H10.0801C9.84011 2.39999 9.65011 2.56999 9.61011 2.80999L9.25011 5.34999C8.66011 5.58999 8.12011 5.91999 7.63011 6.28999L5.24011 5.32999C5.02011 5.24999 4.77011 5.32999 4.65011 5.54999L2.74011 8.86999C2.62011 9.07999 2.66011 9.33999 2.86011 9.47999L4.89011 11.06C4.84011 11.36 4.80011 11.69 4.80011 12C4.80011 12.31 4.82011 12.64 4.87011 12.94L2.84011 14.52C2.66011 14.66 2.61011 14.93 2.72011 15.13L4.64011 18.45C4.76011 18.67 5.01011 18.74 5.23011 18.67L7.62011 17.71C8.12011 18.09 8.65011 18.41 9.24011 18.65L9.60011 21.19C9.65011 21.43 9.84011 21.6 10.0801 21.6H13.9201C14.1601 21.6 14.3601 21.43 14.3901 21.19L14.7501 18.65C15.3401 18.41 15.8801 18.09 16.3701 17.71L18.7601 18.67C18.9801 18.75 19.2301 18.67 19.3501 18.45L21.2701 15.13C21.3901 14.91 21.3401 14.66 21.1501 14.52L19.1401 12.94ZM12.0001 15.6C10.0201 15.6 8.40011 13.98 8.40011 12C8.40011 10.02 10.0201 8.39999 12.0001 8.39999C13.9801 8.39999 15.6001 10.02 15.6001 12C15.6001 13.98 13.9801 15.6 12.0001 15.6Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_71_632">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Настройки
                        </span>
                    </div>
                </NavLink>
            </li> */}
            <li>
                <a role='button' onClick={() => {
                    if (window.confirm("Хотите выйти?"))
                        logout();
                }}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_420_8619)">
                                <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="#A3A3A3" />
                            </g>
                            <defs>
                                <clipPath id="clip0_420_8619">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            Выйти
                        </span>
                    </div>
                </a>
            </li>
        </ul>
    );
}

export default connect(undefined, { logout })(Sidebar);