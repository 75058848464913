import React from 'react';

export default function Modal({ isOpen, className = "", setIsOpen, closeOnClickOutside = true, label, children }) {
    return (
        <div className={"popup " + (isOpen ? " show" : "") + (className ? " " + className : "")}>
            <div className="popup-content">
                <div className="popup-header">
                    <div className="title">{label}</div>

                    {closeOnClickOutside && <a role="button" onClick={() => {
                        setIsOpen(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <g clipPath="url(#clip0_120_17066)">
                                <path d="M25.3327 8.54663L23.4527 6.66663L15.9993 14.12L8.54602 6.66663L6.66602 8.54663L14.1193 16L6.66602 23.4533L8.54602 25.3333L15.9993 17.88L23.4527 25.3333L25.3327 23.4533L17.8793 16L25.3327 8.54663Z" fill="#111111" />
                            </g>
                            <defs>
                                <clipPath id="clip0_120_17066">
                                    <rect width="32" height="32" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>}
                </div>

                <div className="popup-body">
                    {children}
                </div>
            </div>
        </div>
    );
}