import React from 'react';

export default function ModalSelect({ name, defaultValue = "", required = false, type = "text", options = [], label, register, errors }) {

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select type={type} className={errors[name]?.type} id={name} {...register(name, { required, defaultValue })}>
                {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
        </div>
    );
}