import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { post } from '../../utils/request';
import UnsortedFields from './unsorteds/UnsortedFields';

export default function UnsortedForm({ setIsOpen, fetchModels, models }) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        reset
    } = useForm({
        defaultValues: {
            objects: models.map(model => {
                return {
                    id: model.id,
                    name: model.name,
                    partner_id: "",
                    object_id: "",
                    rate: "",
                    platform: model.platform,
                    source_id: model.source_id,
                    coauthors: [{ partner_id: "", rate: "" }]
                };
            })
        }
    });

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);

            let res = await post(`unsorted/create`, data);

            if (res.status == "success") {
                toast.success(res.message);
                setIsOpen(false);
                fetchModels && fetchModels();
            }
        } catch (ex) {
            toast.error("Не удалось сохранить объект");
        }

        setIsLoading(false);
        return;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='pt-4' style={{ minWidth: "584px" }}>
            <UnsortedFields control={control} getValues={getValues} register={register} setValue={setValue} errors={errors} />

            <div className="d-flex justify-content-end mt-3">
                <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    {isLoading && <i className="fa fa-spinner rotating me-2"></i>}
                    {"Редактировать объектов"}
                </button>
            </div>
        </form>
    );
}