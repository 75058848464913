import React from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import ModalInput from '../ModalInput';
import AsyncCreatablePartner from '../AsyncCreatablePartner';
import AsyncCreatableObject from '../AsyncCreatableObject';
import UnsortedCoAuthors from './UnsortedCoAuthors';

export default function UnsortedFields({ control, register, setValue, getValues, errors }) {
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "objects",
    });

    const watch = useWatch({ control, name: "objects" });

    return (
        <>
            {
                fields.map((item, index) => {
                    const fieldName = `objects[${index}]`;
                    console.log(item);
                    return (
                        <fieldset className={'object-item'} name={fieldName} key={fieldName}>
                            <div className="row">
                                <div className="col-md-8">
                                    <ModalInput register={register} name={`${fieldName}.name`} label={"Название"} defaultValue={item.name} readOnly required errors={errors} />
                                </div>
                                <div className="col-md-4 mt-2 d-flex align-items-center">
                                    {item.platform.image ? <img src={item.platform.image} style={{ height: 20 }} /> : <p>{item.platform.id}</p>}
                                    <p className='mb-0 ms-2'>{item.source_id}</p>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="d-flex justify-content-between">
                                    <label htmlFor={`${fieldName}.partner_id`}>Автор</label>
                                    {fields.length > 1 && <a role="button" className='for-all' onClick={() => {
                                        for (let i = 0; i < watch.length; i++) {
                                            setValue(`objects[${i}].partner_id`, watch[index].partner_id);
                                        }
                                    }}>Для всех</a>}
                                </div>
                                <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name={`${fieldName}.partner_id`}
                                    render={({ field }) => (
                                        <AsyncCreatablePartner
                                            {...field}
                                            key={`${fieldName}.partner_id`}
                                            className={"modal-multi-select " + (errors.partner_id?.type)}
                                            placeholder=" - Выберите партнера - "
                                        />
                                    )}
                                />
                            </div>

                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor={`${fieldName}.object_id`}>Объект</label>
                                            {fields.length > 1 && <a role="button" className='for-all' onClick={() => {
                                                for (let i = 0; i < watch.length; i++) {
                                                    setValue(`objects[${i}].object_id`, watch[index].object_id);
                                                }
                                            }}>Для всех</a>}
                                        </div>

                                        <Controller
                                            control={control}
                                            rules={{ required: true }}
                                            name={`${fieldName}.object_id`}
                                            render={({ field }) => (
                                                <AsyncCreatableObject
                                                    {...field}
                                                    key={watch[index].object_id}
                                                    className={"modal-multi-select " + (errors.object_id?.type)}
                                                    placeholder=" - Выберите объекта - "
                                                    partner_id={watch[index].partner_id}
                                                    setValue={setValue}
                                                    setAuthors={true}
                                                    platform_id={watch[index].platform.id}
                                                    index={index}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 flex-grow-1">
                                    <ModalInput name={`${fieldName}.rate]`} label={`Ставка`} register={register} required errors={errors} forAll={fields.length > 1 && <a role="button" className='for-all' onClick={() => {
                                        for (let i = 0; i < watch.length; i++) {
                                            setValue(`objects[${i}].rate`, watch[index].rate);
                                        }
                                    }}>Для всех</a>} />
                                </div>
                                <div className="col d-flex">
                                    <a className='fill-24'></a>
                                    <a className='fill-24 ms-2'></a>
                                </div>
                            </div>

                            <UnsortedCoAuthors register={register} nestIndex={index} control={control} errors={errors} />
                        </fieldset>
                    )
                })
            }
        </>
    );
}